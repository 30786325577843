import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Backdrop, Card, CircularProgress, Fade, Modal } from "@mui/material";
import {
	useSignInWithEmailAndPassword,
	useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import auth from "../../Firebase/firebase.config";
import Swal from "sweetalert2";
import GoogleIcon from "@mui/icons-material/Google";
import SignUp from "../SignUp/SignUp";
import ResetPass from "../ResetPass/ResetPass";
import { useAPI } from "../../apiContext";
import { signOut } from "firebase/auth";
import CancelIcon from "@mui/icons-material/Cancel";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	maxWidth: "95vw",
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};
export default function Login() {
	const { databyEmail } = useAPI();
	const navigate = useNavigate();
	const location = useLocation();
	const [signInWithGoogle, user2, error2, loading2] = useSignInWithGoogle(auth);
	const [signInWithEmailAndPassword, user, loading, error] =
		useSignInWithEmailAndPassword(auth);
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [loggedUser, setLoggedUser] = React.useState(null);
	let from = location.state?.from?.pathname || "/";
	if (user || loggedUser || user2) {
		navigate(from, { replace: true });
	}
	React.useEffect(() => {
		if (error) {
			Swal.fire({
				icon: "error",
				title: `${
					error?.message === "Firebase: Error (auth/wrong-password)."
						? "Wrong Password, Try again or Reset your password."
						: error?.message
				}`,
				showConfirmButton: true,
			});
		}
		if (user) {
			Swal.fire({
				icon: "success",
				title: `Welcome ${(user || user2)?.user?.email}`,
				showConfirmButton: true,
			});
		}
	}, [error, user, user2, error2]);

	const [signup, setSignup] = React.useState(false);
	const [resetpass, setResetpass] = React.useState(false);
	const [open, setOpen] = React.useState(databyEmail?.email ? true : false);
	React.useEffect(() => {
		setOpen(
			databyEmail?.email && databyEmail?.email !== "Not Found" ? true : false,
		);
	}, [databyEmail]);

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			{!signup ? (
				<>
					{!resetpass ? (
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'
							minHeight='100vh'>
							<Box style={{ marginTop: 3, textAlign: "center" }}>
								<Box>
									<Avatar sx={{ mx: "auto", my: 1 }} className='bgColor'>
										<LockOutlinedIcon />
									</Avatar>
									<Typography
										component='h1'
										variant='h5'
										className='textColor'
										sx={{ fontWeight: "bold" }}>
										Sign in
									</Typography>
								</Box>
								<TextField
									margin='normal'
									required
									fullWidth
									id='email'
									label='Email Address'
									name='email'
									autoComplete='email'
									autoFocus
									onChange={(e) => setEmail(e.target.value)}
								/>
								<Typography
									variant='h6'
									className='textColor'
									sx={{ textDecoration: "underline", cursor: "pointer" }}
									onClick={() => setSignup(true)}>
									Don't Have Account? Create Now...
								</Typography>
								<TextField
									margin='normal'
									required
									fullWidth
									name='password'
									label='Password'
									type='password'
									id='password'
									autoComplete='current-password'
									onChange={(e) => setPassword(e.target.value)}
								/>
								<Typography
									variant='h6'
									className='textColor'
									sx={{ textDecoration: "underline", cursor: "pointer" }}
									onClick={() => setResetpass(true)}>
									Forgot your password ? Reset Now !
								</Typography>
								<Button
									className='confirm'
									onClick={() => signInWithEmailAndPassword(email, password)}
									fullWidth
									variant='contained'
									sx={{
										mt: 1,
									}}>
									Sign In
								</Button>
								-------- or --------
								<Button
									fullWidth
									className='confirm'
									onClick={() => signInWithGoogle()}
									sx={{ fontWeight: "bold" }}>
									<GoogleIcon sx={{ mr: 1 }} /> SignIn With Google
								</Button>
							</Box>
						</Box>
					) : (
						<ResetPass
							setResetpass={setResetpass}
							setLoggedUser={setLoggedUser}
						/>
					)}
				</>
			) : (
				<>
					<SignUp setSignup={setSignup} setLoggedUser={setLoggedUser} />
				</>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading || loading2}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style}>
							<Box>
								<Card
									className='borderColor'
									sx={{
										p: 2,
										my: 1,
										minWidth: "300px",
										display: "flex",
										flexDirection: "column",
										alignContent: "center",
										overflow: "visible",
										border: "2px solid ",
										textAlign: "center",
										borderRadius: 3,
									}}>
									<CancelIcon
										onClick={() => setOpen(false)}
										className='textColor'
										sx={{
											position: "fixed",
											top: "13px",
											right: "5px",
											cursor: "pointer",
										}}
									/>
									<Typography
										variant='h5'
										className='textColor'
										sx={{ fontWeight: "bold" }}>
										Welcome Back
									</Typography>
									<Typography
										variant='h6'
										className='textColor'
										sx={{ fontWeight: "bold", mb: 2 }}>
										{databyEmail?.email}
									</Typography>
									<Link to='/dashboard' style={{ textDecoration: "none" }}>
										<Button sx={{ border: "1px solid", width: "100%" }}>
											Go to Dashboard
										</Button>
									</Link>
									-------- or --------
									<Button
										sx={{ border: "1px solid", width: "100%" }}
										onClick={() => signOut(auth)}
										color='inherit'
										className='logout'>
										LogOut
									</Button>
								</Card>
							</Box>
						</Box>
					</Fade>
				</Modal>
			)}
		</Container>
	);
}
