import * as React from "react";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import {
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import axios from "axios";
import Swal from "sweetalert2";
import GetAuth from "../../../GetAuth";

const W3Form = ({ data }) => {
	const [user] = GetAuth();
	const [imageLink2, setImageLink2] = useState(null);
	const [loading, setLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "Payment");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/america-solar/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			photoId: "",
			w9AddressLine1: "",
			w9AddressLine2: "",
			w9City: "",
			w9Country: "",
			w9FullName: "",
			w9Phone: "",
			w9Ssi: "",
			w9State: "",
			w9TinNumner: "",
			w9Zip: "",
		},
	});
	const [datas, setDatas] = useState();
	React.useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_API_PATH}/paymentInfo/${
					data?.url || data?.user
				}`,
			)
			.then((res) => {
				reset(res.data?.w9Info);
				setDatas(res.data);
			});
	}, [reset, data]);
	const onSubmit = (data) => {
		setSubmitting(true);
		const info = { ...data, photoId: imageLink2 };
		const submitTime = new Date().toLocaleString("en-US", {
			timeZone: "America/Los_Angeles",
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			timeZoneName: "short",
		});
		axios
			.put(`${process.env.REACT_APP_API_PATH}/paymentInfow9/${datas?._id}`, {
				info,
				submitTime,
			})
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<div>
			<Box>
				<p className='textColor'>Last Updated: {datas?.submitTime || "N/A"}</p>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h2'>
					W-9
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)} method='post'>
					<Grid
						container
						spacing={1}
						sx={{ mb: 2, justifyContent: "center !important", mx: "auto" }}>
						{data?.email === user?.email && (
							<Grid item md={4} xs={12}>
								<Box
									display='flex'
									alignItems='center'
									justifyContent='center'
									sx={{ mb: 2 }}>
									{!imageLink2 && (
										<label
											className='bgColor'
											htmlFor='icon-button-file'
											style={{
												display: "flex",
												alignItems: "center",
												flexDirection: "column",
												justifyContent: "center",
												borderRadius: 5,
												width: "250px",
												height: "150px",
											}}>
											<Input
												accept='image/*'
												id='icon-button-file'
												type='file'
												onChange={uploadImage}
											/>
											<IconButton
												sx={{ mx: 2 }}
												color='primary'
												aria-label='upload picture'
												component='span'>
												<PermIdentityIcon
													fontSize='large'
													sx={{ fontWeight: "bold" }}
												/>
											</IconButton>
											<Typography
												sx={{ color: "white" }}
												variant='h6'
												component='div'
												gutterBottom>
												Upload Photo ID*
											</Typography>
										</label>
									)}
								</Box>
							</Grid>
						)}
						{(loading || imageLink2) && (
							<Grid item md={4} xs={12}>
								{loading ? (
									<Box sx={{ m: 2 }}>
										<CircularProgress className='textColor' />
									</Box>
								) : (
									<>
										{!loading && imageLink2 && (
											<img
												src={imageLink2}
												style={{ width: "250px", height: "150px" }}
												alt=''
											/>
										)}
									</>
								)}
							</Grid>
						)}
						{datas?.w9Info?.photoId && !loading && !imageLink2 && (
							<Grid item md={4} xs={12}>
								<Box sx={{ mx: "auto" }}>
									<img
										src={datas?.w9Info?.photoId}
										style={{ width: "250px", height: "150px" }}
										alt=''
									/>
								</Box>
							</Grid>
						)}
					</Grid>
					<Grid container spacing={2}>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								sx={{ width: "100%" }}
								id='outlined-basic'
								type={"text"}
								name='Full Name'
								label='Full Name'
								{...register("w9FullName", { required: true })}
							/>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								sx={{ width: "100%" }}
								id='outlined-basic'
								type={"text"}
								name='SSI'
								label='SSI'
								{...register("w9Ssi", { required: true })}
							/>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								sx={{ width: "100%" }}
								id='outlined-basic'
								type={"text"}
								name='Address'
								label='Address'
								{...register("w9AddressLine1", { required: true })}
							/>
						</Grid>

						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<Grid container spacing={2}>
								<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
									<TextField
										InputLabelProps={{
											shrink: true,
										}}
										sx={{ width: "100%" }}
										id='outlined-basic'
										type={"text"}
										name='City'
										label='City'
										{...register("w9City", { required: true })}
									/>
								</Grid>
								<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
									<TextField
										InputLabelProps={{
											shrink: true,
										}}
										sx={{ width: "100%" }}
										id='outlined-basic'
										type={"text"}
										name='State/Region'
										label='State/Region'
										{...register("w9State", { required: true })}
									/>
								</Grid>
								<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
									<TextField
										InputLabelProps={{
											shrink: true,
										}}
										sx={{ width: "100%" }}
										id='outlined-basic'
										type={"text"}
										name='Zip/Postal Code'
										label='Zip/Postal Code'
										{...register("w9Zip", { required: true })}
									/>
								</Grid>
								<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
									<TextField
										InputLabelProps={{
											shrink: true,
										}}
										sx={{ width: "100%" }}
										id='outlined-basic'
										type={"text"}
										name='Country'
										label='Country'
										{...register("w9Country", { required: true })}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								sx={{ width: "100%" }}
								id='outlined-basic'
								type={"tel"}
								name='Phone Number'
								label='Phone Number'
								placeholder='+1-212-456-7890'
								{...register("w9Phone", { required: true })}
							/>
						</Grid>
						{data?.email === user?.email && (
							<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
								{
									<Button
										type='submit'
										variant='contained'
										className='button border'
										sx={{
											my: 1,
											py: 0.5,
											width: "150px",
											border: "2px solid",
											backgroundColor: "transparent",
										}}>
										Submit
									</Button>
								}
							</Grid>
						)}
					</Grid>
				</form>
			</Box>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting || !datas}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default W3Form;
