import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Typography, Backdrop } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "30vw", sm: "60vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const Accesses = ({ open, setOpen, user, setSubmitting }) => {
	const [state, setState] = useState({});
	useEffect(() => {
		setState({
			Canvass: user?.Canvass === "Yes" ? true : false,
			RPS: user?.RPS === "Yes" ? true : false,
			TMOSP: user?.TMOSP === "Yes" ? true : false,
			TMOSV: user?.TMOSV === "Yes" ? true : false,
			GSW: user?.GSW === "Yes" ? true : false,
			podcast: user?.podcast === "Yes" ? true : false,
			WP: user?.WP === "Yes" ? true : false,
			SpinD: user?.SpinD === "Yes" ? true : false,
			Sunova: user?.Sunova === "Yes" ? true : false,
			FundingPrep: user?.FundingPrep === "Yes" ? true : false,
			FundingBoard: user?.FundingBoard === "Yes" ? true : false,
			L3Calculator: user?.L3Calculator === "Yes" ? true : false,
			L2Calculator: user?.L2Calculator === "Yes" ? true : false,
			Tutorials: user?.Tutorials === "Yes" ? true : false,
			L1Marketing: user?.L1Marketing === "Yes" ? true : false,
			L2Presentation: user?.L2Presentation === "Yes" ? true : false,
			L3Design: user?.L3Design === "Yes" ? true : false,
			QuickStart: user?.QuickStart === "Yes" ? true : false,
			ADTRPS: user?.ADTRPS === "Yes" ? true : false,
			TranzactCard: user?.TranzactCard === "Yes" ? true : false,
		});
	}, [user]);
	const allStates = [
		{
			name: "Canvass",
			value: "Canvass",
		},
		{
			name: "RPS",
			value: "RPS",
		},
		{
			name: "The Master of Success Planer",
			value: "TMOSP",
		},
		{
			name: "The Master of Success Video",
			value: "TMOSV",
		},
		{
			name: "Goal Setting Workshop",
			value: "GSW",
		},
		{
			name: "Podcast",
			value: "podcast",
		},
		{
			name: "Winning Presentation",
			value: "WP",
		},
		{
			name: "Spin Doctoring",
			value: "SpinD",
		},
		{
			name: "Sunova",
			value: "Sunova",
		},
		{
			name: "Funding Prep",
			value: "FundingPrep",
		},
		{
			name: "Funding Board",
			value: "FundingBoard",
		},
		{
			name: "Other Options",
			value: "L3Calculator",
		},
		{
			name: "L2 Calculator",
			value: "L2Calculator",
		},
		{
			name: "Tutorials",
			value: "Tutorials",
		},
		{
			name: "L1 Marketing",
			value: "L1Marketing",
		},
		{
			name: "L2 Presentation",
			value: "L2Presentation",
		},
		{
			name: "L3 Design",
			value: "L3Design",
		},
		{
			name: "Quick Start",
			value: "QuickStart",
		},
		{
			name: "HD Floor Training",
			value: "ADTRPS",
		},
		{
			name: "Sunnova Training",
			value: "TranzactCard",
		},
	];

	return (
		<div>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={open}>
					<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
						<CancelIcon
							onClick={() => setOpen(false)}
							className='textColor'
							sx={{
								position: "fixed",
								top: "5px",
								right: "5px",
								cursor: "pointer",
							}}
						/>
						<Typography
							variant='h4'
							gutterBottom
							className='textColor'
							sx={{ fontWeight: "bold" }}>
							Access Table
						</Typography>
						<Box
							sx={{
								"& table, th, td": {
									border: "1px solid",
								},
								"& th": {
									fontWeight: "bold",
								},
							}}>
							<table
								style={{
									width: "100%",
									textAlign: "center",
									borderCollapse: "collapse",
									border: "1px solid black",
								}}>
								<tr>
									<th>Tabs</th>
									<th>Access</th>
								</tr>
								{allStates?.map((values) => (
									<tr>
										<td>{values?.name}</td>
										<td>
											<input
												type='checkbox'
												checked={state?.[values?.value]}
												onChange={() =>
													Swal.fire({
														title: "Are you sure?",
														icon: "warning",
														showCancelButton: true,
														confirmButtonColor: "#3085d6",
														cancelButtonColor: "#d33",
														confirmButtonText: `Yes, Change Access!`,
													}).then((result) => {
														if (result.isConfirmed) {
															setSubmitting(true);
															axios
																.put(
																	`${process.env.REACT_APP_API_PATH}/${values?.value}Access/${user?._id}`,
																	{
																		confirmation:
																			state?.[values?.value] === true
																				? "No"
																				: "Yes",
																	},
																)
																.then(function (response) {
																	setSubmitting(false);
																	Swal.fire({
																		icon: "success",
																		title: "Successfully Updated",
																		showConfirmButton: false,
																		timer: 1500,
																	});
																	setState({
																		...state,
																		[values?.value]: !state?.[values?.value],
																	});
																})
																.catch(function (error) {
																	console.log(error);
																});
														} else {
															setSubmitting(false);
														}
													})
												}
											/>{" "}
										</td>
									</tr>
								))}
							</table>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
};

export default Accesses;
