import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	CircularProgress,
	Backdrop,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import FaceIcon from "@mui/icons-material/Face";
import { io } from "socket.io-client";
import Chat from "./AdminSection/AllUsers/Chat";
import ChatIcon from "@mui/icons-material/Chat";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "40vw", sm: "60vw", xs: "90vw" },
	height: "auto",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const socket = io(`${process.env.REACT_APP_API_PATH}`);

const UnreadChat = ({ open, setOpen, unRead }) => {
	const [openChat, setOpenChat] = useState(false);
	const [room, setRoom] = useState();
	return (
		<Box>
			{unRead ? (
				<Modal
					className='chat'
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => {
									setOpen(false);
									/* socket.emit("leave_room", room); */
								}}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>{" "}
							{unRead?.length > 0 ? (
								<Box sx={{ p: 1 }}>
									{unRead?.map((chat) => (
										<ListItem
											button
											onClick={() => {
												setOpenChat(true);
												setRoom(chat?.chatRoom);
												socket.emit("join_room", chat?.chatRoom);
											}}>
											<ListItemIcon
												sx={{ justifyContent: "center", color: "white" }}>
												<FaceIcon sx={{ color: "white !important" }} />
											</ListItemIcon>
											<ListItemText
												primary={`${
													chat?.chats?.filter((c) => c?.adminSeen === false)
														?.length
												} new message (from ${chat?.chatRoom})`}
											/>
											<ListItemIcon
												sx={{ justifyContent: "center", color: "white" }}>
												<ChatIcon sx={{ color: "white !important" }} />
											</ListItemIcon>
										</ListItem>
									))}
								</Box>
							) : (
								<ListItem button>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<FaceIcon sx={{ color: "white !important" }} />
									</ListItemIcon>
									<ListItemText primary={`No New messages`} />
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<ChatIcon sx={{ color: "white !important" }} />
									</ListItemIcon>
								</ListItem>
							)}
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
				open={!unRead}>
				<CircularProgress color='inherit' />
			</Backdrop>{" "}
			{openChat && (
				<Chat
					openChat={openChat}
					setOpenChat={setOpenChat}
					socket={socket}
					room={room}
				/>
			)}
		</Box>
	);
};

export default UnreadChat;
