import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Button,
	Table,
	Paper,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Container,
	Backdrop,
	CircularProgress,
	Chip,
	Box,
	Fade,
	Modal,
} from "@mui/material";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import ReplayIcon from "@mui/icons-material/Replay";
import CalanderEdit from "./CalanderEdit";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "70vw", sm: "80vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};
const AllEvents = ({ setState, opens, setOpens, data }) => {
	const [edit, setEdit] = useState(false);
	const [calanders, setCalanders] = useState();
	const [deleted, setDeleted] = useState(false);
	const [select, setSelect] = useState();
	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/calander`)
			.then((res) => res.json())
			.then((data) => {
				setCalanders(data.reverse());
			});
	}, [deleted]);

	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setDeleted(true);
				setState(true);
				axios
					.delete(`${process.env.REACT_APP_API_PATH}/calander/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That event has been deleted.", "success");
						setDeleted(false);
						setState(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	let count = 1;
	return (
		<Container>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={opens}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={opens}>
					<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
						<CancelIcon
							onClick={() => setOpens(false)}
							className='textColor'
							sx={{
								position: "fixed",
								top: "5px",
								right: "5px",
								cursor: "pointer",
							}}
						/>
						<Typography
							className='textColor'
							sx={{ fontWeight: "bold", mt: 1.5 }}
							variant='h4'>
							All Events
						</Typography>
						<Typography className='textColor'>
							Last Added Event in{" "}
							{calanders?.[calanders?.length - 1]?.submitTime || "N/A"}
						</Typography>
						<Paper
							className='container'
							sx={{
								overflow: "auto",
								maxHeight: "85vh",
								maxWidth: "90vw",
								m: 2,
								width: "100%",
							}}>
							<Table size='small' stickyHeader aria-label='sticky table'>
								<TableHead sx={{ th: { fontWeight: "bold" } }}>
									<TableRow>
										<TableCell align='left'>No</TableCell>
										<TableCell align='left'>From</TableCell>
										<TableCell align='left'>To</TableCell>
										<TableCell align='left'>Event Title</TableCell>
										<TableCell align='left'>Color</TableCell>
										<TableCell align='left'>Action</TableCell>
									</TableRow>
								</TableHead>
								{calanders?.length > 0 ? (
									<TableBody sx={{ td: { py: 1 } }}>
										{calanders?.map((e) => (
											<TableRow
												key={e?._id}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}>
												<TableCell align='left'>{count++}</TableCell>
												<TableCell align='left'>{e?.from}</TableCell>
												<TableCell align='left'>{e?.to}</TableCell>
												<TableCell align='left'>
													{e?.title.slice(0, 50)}....
												</TableCell>
												<TableCell align='left'>
													<Chip
														style={{
															backgroundColor: e?.color,
															width: "50px",
														}}
													/>
												</TableCell>
												<TableCell align='left' className='actionButton'>
													<Box sx={{ display: "flex" }}>
														<Button
															onClick={() => {
																setSelect(e);
																setEdit(true);
															}}
															sx={{
																fontWeight: "bold",
																border: "2px solid",
																backgroundColor: "transparent",
																borderRadius: "25px",
															}}
															variant='contained'>
															<ReplayIcon />
														</Button>
														<Button
															onClick={() => handleDelete(e?._id)}
															sx={{
																fontWeight: "bold",
																border: "2px solid",
																backgroundColor: "transparent",
																borderRadius: "25px",
															}}
															variant='contained'>
															<DeleteIcon />
														</Button>
													</Box>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								) : (
									<TableHead sx={{ th: { fontWeight: "bold" } }}>
										<TableRow>
											<TableCell align='left'>N/A</TableCell>
											<TableCell align='left'>N/A</TableCell>
											<TableCell align='left'>N/A</TableCell>
											<TableCell align='left'>N/A</TableCell>
											<TableCell align='left'>N/A</TableCell>
											<TableCell align='left'>N/A</TableCell>
										</TableRow>
									</TableHead>
								)}
							</Table>
						</Paper>
						<Backdrop
							sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
							open={!calanders || deleted}>
							<CircularProgress color='inherit' />
						</Backdrop>
					</Box>
				</Fade>
			</Modal>
			{edit && (
				<CalanderEdit
					setState={setState}
					open={edit}
					setOpen={setEdit}
					data={select}
				/>
			)}
		</Container>
	);
};

export default AllEvents;
