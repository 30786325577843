import {
	CircularProgress,
	Backdrop,
	Button,
	Grid,
	InputLabel,
	TextField,
	Typography,
	Box,
	IconButton,
} from "@mui/material";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import styled from "styled-components";
import { useEffect } from "react";
import { useAPI } from "../../apiContext";

const ScheduleQuestions = ({ selectedDateTime, selectProduct }) => {
	const { data, databyEmail } = useAPI();
	const url = window.location.pathname;
	const [selectuser, setSelectuser] = useState();
	const [dashboard, setDashboard] = useState(false);
	const [onSite, setOnsite] = useState(false);
	useEffect(() => {
		if (url.includes("/dashboard/bookAppoinment")) {
			setSelectuser(databyEmail);
			setDashboard(true);
			setOnsite(false);
		}
		if (url.includes("/dashboard/scheduleByAgent")) {
			setSelectuser(databyEmail);
			setDashboard(true);
			setOnsite(true);
		}
		if (url.includes("/schedule")) {
			setSelectuser(data);
			setDashboard(false);
			setOnsite(false);
		}
	}, [data, databyEmail, url]);
	const [team, setTeam] = useState([]);
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/teamsByUser/${selectuser?.url}`)
			.then((res) => res.json())
			.then((data) => {
				setTeam(data);
			});
	}, [selectuser]);
	const form = useRef();
	const [questions, setQuestions] = useState();
	const [submitting, setSubmitting] = useState(false);
	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_PATH}/findproducts/${selectProduct?._id}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setQuestions(data);
			});
	}, [selectProduct?._id]);
	const { register, handleSubmit, reset } = useForm();
	const status = onSite ? "Closed" : "Pending";
	const type = onSite ? "OnSite" : "Online";

	const closedOn = onSite
		? new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
		  })
		: null;
	const onSubmit = (datass) => {
		const datas = {
			invitationCode: selectuser?.url,
			name: datass?.name,
			phoneNumber: datass?.phoneNumber,
			selectedDateTime,
			selectedProduct: selectProduct,
			allQusetions: datass,
			email: datass?.email,
			bill: imageLink2,
			assignedTo: "None",
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
			status: status,
			scheduleId: `Schedule${Math.floor(100000 + Math.random() * 900000)}`,
			invitedRecrutedBy: selectuser?.recuretedBy,
			scheduleType: type,
			dealClosed: closedOn,
			phone: databyEmail?.phone,
		};
		setSubmitting(true);
		fetch(
			`${process.env.REACT_APP_API_PATH}/findTeam/${team?.underManagerTeam}/${team?.underRegionTeam}/${selectuser?.url}`,
		)
			.then((res) => res.json())
			.then((data) => {
				console.log(data);
			});
		if (onSite) {
			axios
				.post(`${process.env.REACT_APP_API_PATH}/onSiteDeal`, datas)
				.then(function (response) {
					setSubmitting(false);
					Swal.fire({
						icon: "success",
						title: "This deal successfully completed",
						showConfirmButton: true,
					}).then(function () {
						reset();
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			axios
				.post(`${process.env.REACT_APP_API_PATH}/successfullSells`, {
					customer: datass?.name,
					customerPhone: datass?.phoneNumber,
					invitedBy: databyEmail?.url,
					assignedTo: "None",
					product: selectProduct,
					soldOn: new Date().toLocaleString("en-US", {
						timeZone: "America/Los_Angeles",
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
						second: "2-digit",
						timeZoneName: "short",
					}),
					paidStatus: "N/A",
					paidAmount: 0,
					paidOn: "N/A",
					note: "",
					invitedRecrutedBy: databyEmail?.recuretedBy,
					recruterPaidStatus: "N/A",
					recruterPaidAmount: 0,
					recruterPaidOn: "N/A",
					recruterNote: "",
				})
				.then(function (response) {
					setSubmitting(false);
					Swal.fire({
						icon: "success",
						title: "Successfully Done",
						showConfirmButton: true,
						timer: 2500,
					});
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			axios
				.post(`${process.env.REACT_APP_API_PATH}/invitations`, datas)
				.then(function (response) {
					setSubmitting(false);
					Swal.fire({
						icon: "success",
						title: "Your appointment has been scheduled Successfully",
						showConfirmButton: true,
					}).then(function () {
						reset();
						dashboard
							? (window.location.href = `/dashboard`)
							: (window.location.href = `/@${selectuser?.url}`);
					});
				})
				.catch(function (error) {
					console.log(error);
				});
		}
	};
	const [imageLink2, setImageLink2] = useState(null);
	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "ScheduleQuesBill");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/america-solar/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	return (
		<>
			<Grid item xs={12} md={5.5}>
				<Typography
					className='textColor'
					variant='h6'
					sx={{ fontWeight: "bold", mb: 2 }}>
					Enter Details
				</Typography>
				<form ref={form} data-aos='fade-left' onSubmit={handleSubmit(onSubmit)}>
					{questions?.billPhoto === true && (
						<Box
							display='flex'
							flexDirection='column'
							alignItems='center'
							sx={{ my: 2, mx: "auto" }}>
							<label
								className='bgColor'
								htmlFor='icon-button-file'
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									margin: "0 9px",
									borderRadius: 5,
									cursor: "pointer",
								}}>
								<Input
									accept='image/*'
									id='icon-button-file'
									type='file'
									onChange={uploadImage}
								/>
								<Typography
									sx={{ my: 2, ml: 2, color: "white" }}
									variant='button'
									component='div'
									gutterBottom>
									Upload Bill
								</Typography>
								<IconButton
									sx={{ mx: 2 }}
									color='primary'
									aria-label='upload picture'
									component='span'>
									<FileUploadIcon
										fontSize='large'
										sx={{ fontWeight: "bold", color: "white" }}
									/>
								</IconButton>
							</label>

							{loading ? (
								<Box sx={{ my: 2 }}>
									<CircularProgress className='textColor' />
								</Box>
							) : (
								<img src={imageLink2} style={{ width: "200px" }} alt='' />
							)}
						</Box>
					)}
					<InputLabel htmlFor='input-with-icon-adornment'>Name</InputLabel>
					<TextField
						required
						sx={{ width: "100%", mb: 2 }}
						id='"outlined-multiline-flexible'
						{...register("name", { required: true })}
					/>
					<InputLabel htmlFor='input-with-icon-adornment'>Email</InputLabel>
					<TextField
						required
						sx={{ width: "100%", mb: 2 }}
						id='"outlined-multiline-flexible'
						type='email'
						{...register("email", { required: true })}
					/>

					<InputLabel htmlFor='input-with-icon-adornment'>
						Phone Number
					</InputLabel>
					<TextField
						required
						type='tel'
						sx={{ width: "100%", mb: 2 }}
						id='"outlined-multiline-flexible'
						{...register("phoneNumber", { required: true })}
					/>
					{questions?.questions?.map((question) => (
						<>
							<InputLabel htmlFor='input-with-icon-adornment'>
								{question?.question}
							</InputLabel>
							<TextField
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								{...register(question?.inShort, { required: true })}
							/>
						</>
					))}

					<Button
						type='submit'
						className='confirm'
						sx={{ width: "180px", p: 2 }}>
						Schedule Event
					</Button>
				</form>
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={submitting || !questions}>
					<CircularProgress color='inherit' />
				</Backdrop>
			</Grid>
		</>
	);
};

export default ScheduleQuestions;
