import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const ManageFAQ = () => {
	const [deleted, setDeleted] = useState(false);
	const [faq, setFaq] = useState();
	const [question, setQuestion] = useState();
	const [answer, setAnswer] = useState();
	const [submitting, setSubmitting] = useState(false);
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/faq`)
			.then((res) => res.json())
			.then((data) => {
				setFaq(data?.reverse());
			});
	}, [deleted, submitting]);
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setDeleted(true);
				axios
					.delete(`${process.env.REACT_APP_API_PATH}/deleteFaq/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That FAQ has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	const columns = [
		{
			field: "question",
			headerName: "Questions",
			flex: 1,
		},
		{
			field: "answer",
			headerName: "Answer",
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 50,
			renderCell: (params) => {
				return (
					<Button
						className='button border'
						onClick={() => handleDelete(params?.row?._id)}
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'>
						<DeleteIcon />
					</Button>
				);
			},
		},
	];

	const [open, setOpen] = useState(false);
	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ mb: 1, fontWeight: "bold" }}
				variant='h4'
				component='div'
				gutterBottom>
				Add New FAQ
			</Typography>
			<Button
				onClick={() => (open ? setOpen(false) : setOpen(true))}
				variant='contained'
				type='submit'
				sx={{
					mb: 1,
					px: 3,
					fontWeight: "bold",
					borderRadius: "25px",
					backgroundColor: open && "red !important",
				}}>
				{open ? "Close" : "Add New"}
			</Button>
			{open && (
				<Grid container spacing={2}>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							sx={{ width: "100%" }}
							multiline
							rows={2}
							id='outlined-basic'
							name='Question'
							label='Question'
							onChange={(e) => setQuestion(e.target.value)}
						/>
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							sx={{ width: "100%" }}
							multiline
							rows={4}
							id='outlined-basic'
							name='Answer'
							label='Answer'
							onChange={(e) => setAnswer(e.target.value)}
						/>
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<Button
							onClick={() => {
								setSubmitting(true);
								const data = {
									question: question,
									answer: answer,
									submitTime: new Date().toLocaleString("en-US", {
										timeZone: "America/Los_Angeles",
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
										hour: "2-digit",
										minute: "2-digit",
										second: "2-digit",
										timeZoneName: "short",
									}),
									inShort: "faq" + Math.floor(100000 + Math.random() * 900000),
								};
								axios
									.post(`${process.env.REACT_APP_API_PATH}/faq`, data)
									.then(function (response) {
										setSubmitting(false);

										Swal.fire({
											icon: "success",
											title: "Successfully Added",
											showConfirmButton: true,
											timer: 2500,
										});
									})
									.catch(function (error) {
										console.log(error);
									});
							}}
							className='sendButton'
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Add FAQ
						</Button>
					</Grid>
				</Grid>
			)}
			{faq && (
				<Box sx={{ width: "100%", height: "70vh" }}>
					<DataGrid
						rows={faq}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}
			{/* 	<Paper
				className='container'
				sx={{ overflow: "auto", maxHeight: "80vh", maxWidth: "90vw" }}>
				<Table size='small' stickyHeader aria-label='sticky table'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='center' colSpan={3}>
								<Typography
									className='textColor'
									sx={{ fontWeight: "bold" }}
									variant='h4'>
									All FAQ
								</Typography>
								<Typography className='textColor'>
									Last Added in {faq?.[0]?.submitTime || "N/A"}
								</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align='left'>No</TableCell>
							<TableCell align='left'>Questions</TableCell>
							<TableCell align='center'>Action</TableCell>
						</TableRow>
					</TableHead>
					{faq?.length > 0 ? (
						<TableBody sx={{ td: { py: 1 } }}>
							{faq?.map((question, key) => (
								<TableRow
									key={key}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
									}}>
									<TableCell align='left'>{count++}</TableCell>
									<TableCell align='left'>{question?.question}</TableCell>
									<TableCell align='center'>
										<Button
											className='button border'
											onClick={() => handleDelete(question?._id)}
											sx={{
												fontWeight: "bold",
												border: "2px solid",
												backgroundColor: "transparent",
												borderRadius: "25px",
												m: 0.5,
											}}
											variant='contained'>
											<DeleteIcon />
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : (
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='center'>N/A</TableCell>
							</TableRow>
						</TableHead>
					)}
				</Table>
			</Paper> */}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !faq}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default ManageFAQ;
