import React, { useContext, useState, createContext } from "react";
import axios from "axios";
import GetAuth from "./GetAuth";

const APIContext = createContext();

export function APIContextProvider({ children }) {
	const [info, setInfo] = useState([]);
	React.useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_PATH}/colorBorder`).then((res) => {
			setInfo(res.data?.colorBorder);
		});
	}, []);
	const [data, setData] = useState();
	React.useEffect(() => {
		const url = window.location.pathname.split("/")[1];
		fetch(`${process.env.REACT_APP_API_PATH}/alluser/${url || "owner"}`)
			.then((res) => res.json())
			.then((data) => setData(data));
	}, []);
	const [databyEmail, setDatabyEmail] = useState();
	const [user] = GetAuth();
	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/userbyemail/${user?.email}`)
			.then((res) => res.json())
			.then((data) => setDatabyEmail(data))
			.catch((error) => {
				setDatabyEmail({ email: "Not Found" });
			});
	}, [user?.email]);
	const [allUsers, setAllUsers] = useState();
	React.useEffect(() => {
		window.location.pathname.includes("dashboard") &&
			fetch(`${process.env.REACT_APP_API_PATH}/allusersforassign`)
				.then((res) => res.json())
				.then((data) => {
					setAllUsers(data.reverse());
				});
	}, []);
	const menuItems = [
		{ value: "Pending", status: "Pending" },
		{ value: "Pre-site-survey", status: "Sold" },
		{ value: "Closed", status: "Close", role: "Admin" },
		{ value: "Post-site-survey", status: "Post site survey" },
		{ value: "Design-started", status: "Design started" },
		{ value: "Phone-Room-Follow-Up", status: "Phone Room Follow Up" },
		{ value: "Manager-Follow-Up", status: "Rehash" },
		{ value: "Canceled", status: "Canceled" },
		{ value: "Agent-Follow-Up", status: "Agent Follow Up" },
		{ value: "DNQ", status: "DNQ" },
		{ value: "Alarm-Deal", status: "Alarm Deal" },
		{ value: "In-Permitting", status: "In Permitting" },
		{ value: "Install-Scheduled", status: "Install Scheduled" },
		{ value: "M2-Funded", status: "M2 Funded" },
	];

	const SetMenuItems = (value) => {
		return menuItems.find((item) => item.value === value)?.status;
	};
	return (
		<APIContext.Provider
			value={{
				data: data,
				info: info,
				databyEmail: databyEmail,
				allUsers,
				SetMenuItems,
			}}>
			{children}
		</APIContext.Provider>
	);
}

export function useAPI() {
	const context = useContext(APIContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}
