import React from "react";
import Schedule from "../../Schedule/Schedule";

const ScheduleBook = () => {
	return (
		<div>
			<Schedule />
		</div>
	);
};

export default ScheduleBook;
