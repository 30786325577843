import { Box, Container, Fab, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import axios from "axios";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useAPI } from "../../../../apiContext";

const ScheduleOrJoin = () => {
	const { data } = useAPI();
	const [info, setInfo] = useState();
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_PATH}/ScheduleOrJoinDetails`)
			.then((res) => {
				setInfo(res.data?.details);
			});
	}, []);
	return (
		<Container sx={{ pt: 5 }}>
			{data?._id ? (
				<Grid container spacing={4}>
					<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
						<Box
							sx={{ border: "2px solid", borderRadius: "10px" }}
							className='borderColor'>
							<Box
								sx={{ mt: -3 }}
								onClick={() =>
									window.open(
										`${window?.location?.origin?.toString()}/@${
											data?.url
										}/schedule`,
										"_blank",
									)
								}>
								<Fab
									className='pulse'
									variant='extended'
									sx={{
										px: 4,
										mx: 0.5,
										fontWeight: "bold",
									}}>
									<EventAvailableIcon sx={{ mr: 1.5 }} />
									Get a free quote
								</Fab>
							</Box>
							<Typography
								variant='body2'
								gutterBottom
								className='textColor'
								sx={{
									fontWeight: "bold",
									py: 2,
									px: 0.5,
									whiteSpace: "pre-line",
								}}>
								{info?.scheduleDetails}
							</Typography>
						</Box>
					</Grid>
					<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
						<Box
							sx={{ border: "2px solid", borderRadius: "10px" }}
							className='borderColor'>
							<Box
								sx={{ mt: -3 }}
								onClick={() =>
									window.open(
										`${window?.location?.origin?.toString()}/@${
											data?.url
										}/jointeam`,
										"_blank",
									)
								}>
								<Fab
									className='pulse'
									variant='extended'
									sx={{
										px: 4,
										mx: 0.5,
										fontWeight: "bold",
									}}>
									<GroupAddIcon sx={{ mr: 1.5 }} />
									Join Our Team
								</Fab>
							</Box>
							<Typography
								variant='body2'
								gutterBottom
								className='textColor'
								sx={{
									fontWeight: "bold",
									py: 2,
									px: 0.5,
									whiteSpace: "pre-line",
								}}>
								{info?.jointeamDetails}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			) : (
				<div className='loader'>
					<PropagateLoader size={11} />
				</div>
			)}
		</Container>
	);
};

export default ScheduleOrJoin;
