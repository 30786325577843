import {
	Backdrop,
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const AllADTProgram = () => {
	const [deleted, setDeleted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [ADTProgram, setADTProgram] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/ADTProgram`)
			.then((res) => res.json())
			.then((data) => {
				setADTProgram(data);
				setNewList(data);
			});
	}, [deleted, submitting]);
	const [newList, setNewList] = useState(ADTProgram);
	console.log(newList);
	const handleDrop = (droppedItem) => {
		if (!droppedItem.destination) return;
		var updatedList = [...newList];
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		setNewList(updatedList);
	};
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setDeleted(true);
				axios
					.delete(`${process.env.REACT_APP_API_PATH}/ADTProgram/${id}`)
					.then(function (response) {
						Swal.fire(
							"Deleted!",
							"That ADT Program has been deleted.",
							"success",
						);
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	const sort = () => {
		setSubmitting(true);
		axios
			.put(`${process.env.REACT_APP_API_PATH}/sortADTProgram`, newList)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Sorted",
					showConfirmButton: true,
					timer: 950000,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<>
			<Paper
				className='container'
				sx={{ overflow: "auto", maxHeight: "85vh", maxWidth: "90vw", m: 2 }}>
				<Table size='small' stickyHeader aria-label='sticky table'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='center' colSpan={4}>
								<Typography
									className='textColor'
									sx={{ fontWeight: "bold" }}
									variant='h4'
									gutterBottom>
									All HD Floor Training
								</Typography>
								<Typography className='textColor'>
									Last changed in {ADTProgram?.[0]?.submitTime || "N/A"}
								</Typography>
								{ADTProgram !== newList && (
									<Button
										sx={{
											backgroundColor: "red !important",
											border: "1px solid",
										}}
										onClick={() => sort()}>
										Click to save changes
									</Button>
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align='left'>Title</TableCell>
							<TableCell align='left'>Posted Time</TableCell>
							<TableCell align='center'>Action</TableCell>
						</TableRow>
					</TableHead>
					<DragDropContext onDragEnd={handleDrop}>
						<Droppable droppableId='list-container'>
							{(provided) => (
								<TableBody
									className='list-container'
									{...provided.droppableProps}
									ref={provided.innerRef}>
									{newList?.map((n, key) => (
										<Draggable key={n?._id} draggableId={n?._id} index={key}>
											{(provided) => (
												<TableRow
													className='item-container'
													ref={provided.innerRef}
													{...provided.dragHandleProps}
													{...provided.draggableProps}>
													<TableCell align='left'>{n?.title}</TableCell>
													<TableCell align='left'>{n?.submitTime}</TableCell>
													<TableCell align='center'>
														<Link
															to={`/dashboard/allADTProgram/${n?._id}`}
															style={{
																textDecoration: "none",
																color: "white",
															}}>
															<Button
																className='button border'
																sx={{
																	fontWeight: "bold",
																	border: "2px solid",
																	backgroundColor: "transparent",
																	borderRadius: "25px",
																	m: 0.5,
																}}
																variant='contained'>
																<EditIcon />
															</Button>
														</Link>
														<Button
															className='button border'
															onClick={() => handleDelete(n?._id)}
															sx={{
																fontWeight: "bold",
																border: "2px solid",
																backgroundColor: "transparent",
																borderRadius: "25px",
																m: 0.5,
															}}
															variant='contained'>
															<DeleteIcon />
														</Button>
													</TableCell>
												</TableRow>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</TableBody>
							)}
						</Droppable>
					</DragDropContext>
				</Table>
			</Paper>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!ADTProgram || deleted || submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</>
	);
};

export default AllADTProgram;
