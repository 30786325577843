import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import ScheduleDetails from "./ScheduleDetails";
import ScheduleDateTime from "./ScheduleDateTime";
import ScheduleQuestions from "./ScheduleQuestions";
import ScheduleSelectProduct from "./ScheduleSelectProduct";

const Schedule = () => {
	const [nextStep, setNextStep] = React.useState(false);
	const [selectedDateTime, setSelectedDateTime] = React.useState();
	const [selectProduct, setSelectProduct] = React.useState();

	return (
		<Container className='schedule'>
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				minHeight='100vh'>
				<Card
					sx={{
						p: 0.5,
						m: { sm: 0, md: 5 },
						borderRadius: 5,
					}}>
					<CardContent
						sx={{
							display: "flex",
							flexDirection: "column",
							textAlign: "left",
						}}>
						<Grid container spacing={2} alignItems='flex-start'>
							<ScheduleDetails
								setSelectProduct={setSelectProduct}
								selectedDateTime={selectedDateTime}
								setNextStep={setNextStep}
								selectProduct={selectProduct}
								setSelectedDateTime={setSelectedDateTime}
							/>
							{!selectProduct && (
								<ScheduleSelectProduct setSelectProduct={setSelectProduct} />
							)}
							{selectProduct && !nextStep && (
								<ScheduleDateTime
									setNextStep={setNextStep}
									setSelectedDateTime={setSelectedDateTime}
								/>
							)}
							{selectProduct && nextStep && (
								<ScheduleQuestions
									selectedDateTime={selectedDateTime}
									selectProduct={selectProduct}
								/>
							)}
						</Grid>
					</CardContent>
				</Card>
			</Box>
		</Container>
	);
};

export default Schedule;
