import React, { useEffect } from "react";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	Container,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const UpdateLevel2MCQ = () => {
	const { id } = useParams();
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			question: "",
			questionType: "text",
			correctAnswer: "",
			messageForCorrectAnswer: "",
			messageForIncorrectAnswer: "",
			explanation: "",
			answer1: "",
			answer2: "",
			answer3: "",
			answer4: "",
		},
	});
	const [datas, setDatas] = useState({});
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_PATH}/Level2MCQ/${id}`)
			.then((res) => {
				reset(res?.data);
				setDatas(res?.data);
			});
	}, [id, reset]);
	const onSubmit = ({
		question,
		correctAnswer,
		messageForCorrectAnswer,
		messageForIncorrectAnswer,
		explanation,
		answer1,
		answer2,
		answer3,
		answer4,
	}) => {
		const data = {
			question,
			questionType: "text",
			answers: [answer1, answer2, answer3, answer4],
			correctAnswer,
			messageForCorrectAnswer,
			messageForIncorrectAnswer,
			explanation,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		setSubmitting(true);
		axios
			.put(`${process.env.REACT_APP_API_PATH}/editLevel2MCQ/${id}`, data)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: true,
					timer: 950000,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container sx={{ pt: 2 }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'
				gutterBottom>
				Update Level 2 MCQ
			</Typography>
			{datas?._id && (
				<Grid container spacing={2}>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<form data-aos='fade-left' onSubmit={handleSubmit(onSubmit)}>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								label='Question'
								InputLabelProps={{
									shrink: true,
								}}
								{...register("question", { required: true })}
							/>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								label='Choice 1'
								defaultValue={datas?.answers?.[0]}
								InputLabelProps={{
									shrink: true,
								}}
								{...register("answer1", { required: true })}
							/>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								label='Choice 2'
								defaultValue={datas?.answers?.[1]}
								InputLabelProps={{
									shrink: true,
								}}
								{...register("answer2", { required: true })}
							/>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								label='Choice 3'
								defaultValue={datas?.answers?.[2]}
								InputLabelProps={{
									shrink: true,
								}}
								{...register("answer3", { required: true })}
							/>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								label='Choice 4'
								defaultValue={datas?.answers?.[3]}
								InputLabelProps={{
									shrink: true,
								}}
								{...register("answer4", { required: true })}
							/>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								type={"number"}
								label='Correct Answer'
								placeholder='1, 2, 3, or 4'
								InputLabelProps={{
									shrink: true,
								}}
								{...register("correctAnswer", { required: true })}
							/>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								label='Message For Correct Answer'
								InputLabelProps={{
									shrink: true,
								}}
								{...register("messageForCorrectAnswer", { required: true })}
							/>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								label='Message For Correct Answer'
								InputLabelProps={{
									shrink: true,
								}}
								{...register("messageForIncorrectAnswer", { required: true })}
							/>
							<TextField
								required
								sx={{ width: "100%", mb: 2 }}
								id='"outlined-multiline-flexible'
								label='Explanation'
								InputLabelProps={{
									shrink: true,
								}}
								{...register("explanation", { required: true })}
							/>
							<Button
								type='submit'
								variant='contained'
								className='button border'
								sx={{
									width: "100%",
									mb: 2,
									px: 3,
									fontWeight: "bold",
									border: "2px solid",
									backgroundColor: "transparent",
									borderRadius: "25px",
								}}>
								Update
							</Button>
						</form>
					</Grid>
				</Grid>
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !datas?._id}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default UpdateLevel2MCQ;
