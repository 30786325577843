import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { useEffect } from "react";
import { useState } from "react";

const ScheduleSelectProduct = ({ setSelectProduct }) => {
	const [select, setSelect] = React.useState();
	const [products, setProducts] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/products`)
			.then((res) => res.json())
			.then((data) => {
				setProducts(data?.reverse());
			});
	}, []);
	const [selected, setSelected] = useState();

	return (
		<>
			<Grid item xs={12} md={7} className='invite'>
				<Typography
					variant='h5'
					className='textColor'
					sx={{ fontWeight: "bold", mb: 2 }}>
					Select a Product
				</Typography>
				<Grid container spacing={2}>
					{products?.map((product, index) => (
						<Grid key={index} item xs={12} md={6}>
							<Card
								sx={{ mx: 1, borderRadius: 3 }}
								onClick={() => {
									setSelected(product);
									setSelect(product._id);
								}}
								className={
									select === product._id ? "activeCard" : "productCard"
								}>
								<CardMedia
									component='img'
									height='170'
									image={product.image}
									alt={product.name}
								/>
								<Typography variant='h5' sx={{ textAlign: "center", py: 1 }}>
									{product.name}
								</Typography>
							</Card>
						</Grid>
					))}
					<Grid item xs={12} md={12} display='flex' justifyContent='flex-end'>
						{select && (
							<Button
								onClick={() => setSelectProduct(selected)}
								className='confirm'
								variant='contained'
								sx={{
									width: "180px",
									px: 3,
									fontWeight: "bold",
								}}>
								Next
							</Button>
						)}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default ScheduleSelectProduct;
