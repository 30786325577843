import { Button, Card, Chip, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import FaceIcon from "@mui/icons-material/Face";
import { useAPI } from "../../../../apiContext";
import { Link } from "react-router-dom";

const TeamInfoTrainers = () => {
	const { databyEmail } = useAPI();
	const [trainers, setTrainers] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/trainers`)
			.then((res) => res.json())
			.then((data) => {
				setTrainers(data);
			});
	}, []);

	const trainerCheck = trainers?.find((user) =>
		user?.teamLeaders?.some((u) => u?.url === databyEmail?.url),
	);
	return (
		<Box sx={{ p: 2 }}>
			<Divider sx={{ mt: 1, border: "1px solid" }} className='borderColor' />
			<Typography
				className='textColor'
				variant='h5'
				sx={{ fontWeight: "bold", mb: 2 }}>
				Trainers
			</Typography>
			<Grid container spacing={2}>
				{trainers
					?.filter(
						(f) => f?.underTeamLeadTeam === trainerCheck?.underTeamLeadTeam,
					)
					?.filter((d) => d?.trainer === trainerCheck?.trainer)
					?.map((t) => (
						<Grid item md={4} xs={6}>
							<Card sx={{ padding: 1.5, position: "relative" }}>
								<Button sx={{ width: "90%" }}>"{t?.trainer}" Leaders</Button>
								<Box
									sx={{
										display: "flex",
										mt: 1,
										flexWrap: "wrap",
										justifyContent: "center",
									}}>
									{t?.teamLeaders?.map((s) => (
										<>
											<Link
												to={`/dashboard/TeamInfo/${s?.url}`}
												style={{
													textDecoration: "none",
												}}>
												<Chip
													icon={<FaceIcon sx={{ color: "white !important" }} />}
													label={s?.url}
													variant='outlined'
													className='bgColor'
													sx={{
														color: "white",
														cursor: "pointer",
													}}
												/>
											</Link>
										</>
									))}
								</Box>
								<Divider
									sx={{ mt: 1, border: "1px solid" }}
									className='borderColor'
								/>
								<Button sx={{ width: "90%", my: 1 }}>
									"{t?.trainer}" Agents
								</Button>
								<Box
									sx={{
										display: "flex",
										flexWrap: "wrap",
										justifyContent: "center",
									}}>
									{t?.trainerAgents?.map((s) => (
										<>
											<Link
												to={`/dashboard/TeamInfo/${s?.url}`}
												style={{
													textDecoration: "none",
												}}>
												<Chip
													icon={<FaceIcon sx={{ color: "white !important" }} />}
													label={s?.url}
													variant='outlined'
													className='bgColor'
													sx={{
														color: "white",
														cursor: "pointer",
													}}
												/>
											</Link>
										</>
									))}
								</Box>
							</Card>
						</Grid>
					))}
			</Grid>
		</Box>
	);
};

export default TeamInfoTrainers;
