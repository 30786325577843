import React, { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

const ScheduleDateTime = ({ setNextStep, setSelectedDateTime }) => {
	const [date, setDate] = React.useState(new Date());
	const [selectedTime, setSelectedTime] = React.useState();
	const convDateTime =
		date?.toLocaleString()?.split(" ")[0] +
		" " +
		selectedTime +
		"-" +
		Intl.DateTimeFormat().resolvedOptions().timeZone;
	const handleTimeDateSelect = () => {
		setNextStep(true);
		setSelectedDateTime(convDateTime);
	};
	const [selectDate, setSelectDate] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/BlockedScheduleDate`)
			.then((res) => res.json())
			.then((data) => {
				setSelectDate(data);
			});
	}, []);

	const dates = selectDate?.map((day) => day.selectedDate).join(",");
	const customDayRenderer = (date, selectedDates, pickersDayProps) => {
		const stringifiedDate = date?.toLocaleString()?.split(", ")[0];
		if (dates.includes(stringifiedDate)) {
			return <PickersDay {...pickersDayProps} disabled />;
		}
		return <PickersDay {...pickersDayProps} />;
	};

	const [manageScheduleTime, setManageScheduleTime] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/ManageScheduleTime`)
			.then((res) => res.json())
			.then((data) => {
				setManageScheduleTime(
					data?.filter(
						(item) =>
							item.day === date?.toLocaleDateString("en", { weekday: "long" }),
					),
				);
			});
	}, [date]);

	function timeConversion(time) {
		time = time
			?.toString()
			?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
		if (time?.length > 1) {
			time = time?.slice(1);
			time[5] = +time[0] < 12 ? " AM" : " PM";
			time[0] = +time[0] % 12 || 12;
		}
		return time?.join("");
	}
	return (
		<>
			<Grid item xs={12} lg={4} className='calander'>
				<Typography variant='h6' sx={{ fontWeight: "bold", my: 1, pl: 2 }}>
					Select Date
				</Typography>
				{selectDate && (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<CalendarPicker
							renderDay={customDayRenderer}
							disablePast
							date={date}
							onChange={(newDate) => setDate(newDate)}
						/>
					</LocalizationProvider>
				)}
			</Grid>
			<Grid
				itemxs={12}
				lg={3}
				sx={{
					mx: "auto",
				}}>
				<Typography variant='h6' sx={{ fontWeight: "bold", my: 1, pl: 2 }}>
					Select Time
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						maxHeight: "380px",
						overflowY: "auto",
					}}>
					{manageScheduleTime?.length === 0 ? (
						<Button
							variant='outlined'
							sx={{
								my: 0.3,
								p: 1,
								fontWeight: "bold",
							}}>
							N/A
						</Button>
					) : (
						<>
							{manageScheduleTime
								?.sort(
									(a, b) =>
										new Date("1970/01/01 " + a.time) -
										new Date("1970/01/01 " + b.time),
								)
								?.map((time, index) => (
									<Button
										key={index}
										className={
											timeConversion(selectedTime) ===
												timeConversion(time?.time) && "selectedTime"
										}
										onClick={() => setSelectedTime(timeConversion(time?.time))}
										variant='outlined'
										sx={{
											my: 0.3,
											p: 1,
											fontWeight: "bold",
										}}>
										{timeConversion(time?.time)}
									</Button>
								))}
						</>
					)}
				</Box>
			</Grid>
			<Grid item xs={12} md={12} display='flex' justifyContent='flex-end'>
				{selectedTime && date && (
					<Button
						onClick={() => handleTimeDateSelect(selectedTime, date)}
						className='confirm'
						sx={{ width: "180px" }}>
						Confirm
					</Button>
				)}
			</Grid>
		</>
	);
};

export default ScheduleDateTime;
