import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	InputLabel,
	Button,
} from "@mui/material";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAPI } from "../../../../apiContext";
import axios from "axios";
import Swal from "sweetalert2";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "50vw", sm: "70vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "left",
};

const SingleUser = ({ user, open, setOpen, setSubmitting }) => {
	const { databyEmail } = useAPI();
	const [nameChange, setNameChange] = React.useState("");
	const [emailChange, setEmailChange] = React.useState("");
	const [phoneNumberChange, setPhoneNumberChange] = React.useState("");

	return (
		<div>
			{databyEmail ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => setOpen(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Box sx={{ mb: 2 }}>
								<Typography
									className='textColor'
									variant='body'
									gutterBottom
									sx={{ fontWeight: "bold", textAlign: "center" }}>
									New User Scheduled by using invitation code : "
									<span style={{ color: "red" }}>{user?.invitationCode}</span>"
									on <span style={{ color: "red" }}>{user?.submitTime}</span>
								</Typography>
							</Box>

							<Grid container spacing={2}>
								{user?.bill && (
									<Grid
										item
										xs={12}
										sx={{ display: "flex", justifyContent: "center" }}>
										<img
											className='borderColor'
											src={user?.bill}
											alt={user?.name}
											style={{
												width: "250px",
												minHeight: "100px",
												border: "1px solid ",
											}}
										/>
									</Grid>
								)}
								<Grid item xs={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment'>
											Name
										</InputLabel>
										<TextField
											required
											sx={{ width: "100%" }}
											id='"outlined-multiline-flexible'
											defaultValue={user?.name}
											onChange={(e) => setNameChange(e.target.value)}
										/>
									</>
								</Grid>

								<Grid item xs={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment'>
											Email
										</InputLabel>
										<TextField
											required
											sx={{ width: "100%" }}
											id='"outlined-multiline-flexible'
											type='email'
											defaultValue={user?.email}
											onChange={(e) => setEmailChange(e.target.value)}
										/>
									</>
								</Grid>

								<Grid item xs={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment'>
											Phone Number
										</InputLabel>
										<TextField
											required
											sx={{ width: "100%" }}
											id='"outlined-multiline-flexible'
											defaultValue={user?.phoneNumber}
											onChange={(e) => setPhoneNumberChange(e.target.value)}
										/>
									</>
								</Grid>

								{user?.selectedProduct?.questions?.map((question) => (
									<>
										<Grid item xs={12}>
											<InputLabel htmlFor='input-with-icon-adornment'>
												{question?.question}
											</InputLabel>
											<TextField
												disabled
												required
												sx={{ width: "100%", backgroundColor: "#d9d6d6b0" }}
												id='"outlined-multiline-flexible'
												value={user?.allQusetions[question?.inShort]}
											/>
										</Grid>
									</>
								))}
							</Grid>
							<Grid item xs={12} sx={{ mt: 2 }}>
								{databyEmail?.role === "Admin" ? (
									<Button
										sx={{
											border: "1px solid",
											py: 1,
										}}
										onClick={() => {
											setSubmitting(true);
											axios
												.put(
													`${process.env.REACT_APP_API_PATH}/inviteUserUpdate/${user?._id}`,
													{
														name: nameChange || user?.name,
														email: emailChange || user?.email,
														phoneNumber: phoneNumberChange || user?.phoneNumber,
													},
												)
												.then(function (response) {
													setSubmitting(false);
													Swal.fire({
														icon: "success",
														title: "Successfully Updated",
														showConfirmButton: false,
														timer: 1500,
													});
												})
												.catch(function (error) {
													console.log(error);
												});
										}}>
										Update Opportunity Details
									</Button>
								) : (
									<Button
										disabled
										sx={{
											border: "1px solid black !important",
											backgroundColor: "#d9d6d6b0 !important",
											py: 1,
											color: "black !important",
										}}>
										Admin can update only
									</Button>
								)}
							</Grid>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
		</div>
	);
};

export default SingleUser;
