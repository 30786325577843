import React from "react";
import { useAPI } from "../../../../apiContext";
import TeamInfoAdmin from "./TeamInfoAdmin";
import TeamInfoManager from "./TeamInfoManager";
import TeamInfoRegional from "./TeamInfoRegional";
import TeamInfoTeamLead from "./TeamInfoTeamLead";
import TeamInfoTrainers from "./TeamInfoTrainers";

const TeamInfo = () => {
	const { databyEmail } = useAPI();
	/* 	const [team, setTeam] = useState([]);
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/teamsByUser/${databyEmail?.url}`)
			.then((res) => res.json())
			.then((data) => {
				setTeam(data);
			});
	}, [databyEmail]);
	console.log(team);
	const [teams, setTeams] = useState([]);
	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_PATH}/findTeam/${team?.underManagerTeam}/${team?.underRegionTeam}/${databyEmail?.url}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setTeams(data);
			});
	}, [team, databyEmail]);
	console.log(teams); */
	/* 	const [teams, setTeams] = useState([]);
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/teams`)
			.then((res) => res.json())
			.then((data) => {
				setTeams(data);
			});
	}, []);
	console.log(teams?.map((team) => team?.url));
	const [regions, setRegions] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/regions`)
			.then((res) => res.json())
			.then((data) => {
				setRegions(data);
			});
	}, []);
	const [managers, setManagers] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/managers`)
			.then((res) => res.json())
			.then((data) => {
				setManagers(data);
			});
	}, []);
	const [teamLeads, setTeamLeads] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/teamLeads`)
			.then((res) => res.json())
			.then((data) => {
				setTeamLeads(data);
			});
	}, []);
	const [trainers, setTrainers] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/trainers`)
			.then((res) => res.json())
			.then((data) => {
				setTrainers(data);
			});
	}, []);
	console.log(
		databyEmail?.role === "Admin"
			? trainers?.find((f) =>
					f?.trainerAgents?.find((d) => d?.url === databyEmail?.url),
			  )
			: "NA",
	); */
	return (
		<>
			{databyEmail?.role === "Admin" && <TeamInfoAdmin />}
			{databyEmail?.role === "Regional" && <TeamInfoRegional />}
			{databyEmail?.role === "Manager" && <TeamInfoManager />}
			{databyEmail?.role === "Team-Lead" && <TeamInfoTeamLead />}
			{databyEmail?.role === "Trainer" && <TeamInfoTrainers />}
		</>
	);
};

export default TeamInfo;
