import { Box, Button, MenuItem, Select } from "@mui/material";
import axios from "axios";
import React from "react";
import Swal from "sweetalert2";

const RoleChnage = ({ setSubmitting, user }) => {
	const [openRole, setOpenRole] = React.useState(false);
	const handleClose = () => {
		setOpenRole(false);
	};

	const handleOpen = () => {
		setOpenRole(true);
	};
	return (
		<Box sx={{ display: "flex" }}>
			<Button
				sx={{ minWidth: "110px", py: 1, height: "40px", my: "auto" }}
				onClick={handleOpen}>
				{user?.role || "N/A"}
			</Button>
			<Select
				sx={{ visibility: "hidden", mr: -12 }}
				open={openRole}
				onClose={handleClose}
				onOpen={handleOpen}
				onChange={(e) => {
					Swal.fire({
						title: "Are you sure?",
						icon: "warning",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: `Yes, Change Role!`,
					}).then((result) => {
						if (result.isConfirmed) {
							setSubmitting(true);
							axios
								.put(
									`${process.env.REACT_APP_API_PATH}/rolechange/${user?._id}`,
									{
										role: e.target.value,
									},
								)
								.then(function (response) {
									setSubmitting(false);
									Swal.fire({
										icon: "success",
										title: "Successfully Updated",
										showConfirmButton: false,
										timer: 1500,
									});
								})
								.catch(function (error) {
									console.log(error);
								});
						} else {
							setSubmitting(false);
						}
					});
				}}
				defaultValue={user?.role}>
				<MenuItem value={"Admin"}>Admin</MenuItem>
				<MenuItem value={"Manager"}>Manager</MenuItem>
				<MenuItem value={"Regional"}>Regional</MenuItem>
				<MenuItem value={"Team-Lead"}>Team-Lead</MenuItem>
				<MenuItem value={"Trainer"}>Trainer</MenuItem>
				<MenuItem value={"Agent"}>Agent</MenuItem>
			</Select>
		</Box>
	);
};

export default RoleChnage;
