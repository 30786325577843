import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	FormControl,
	NativeSelect,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useAPI } from "../../../../apiContext";
import { useParams } from "react-router-dom";
import Note from "../InvitedUser/Note";
import SingleUser from "../InvitedUser/SingleUser";

const FilteredOpp = () => {
	const { userName } = useParams();
	const { databyEmail } = useAPI();
	const [open, setOpen] = useState(false);
	const [openNote, setOpenNote] = useState(false);
	const [user, setUser] = useState();
	function setTime(time) {
		const previousZone = new Date(time?.split("-")[0]).toLocaleString("en-US", {
			timeZone: time?.split("-")[1],
		});
		const localZone = new Date(previousZone)?.toLocaleString("en-US", {
			timeZone: Intl.DateTimeFormat().resolvedOptions()?.timeZone,
		});
		return localZone;
	}
	const [allUsers, setAllUsers] = useState();
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/invitatedusers`)
			.then((res) => res.json())
			.then((data) => {
				setAllUsers(data?.data.filter((p) => p?.invitationCode === userName));
			});
	}, [userName]);

	const [filter, setFilter] = useState();
	useEffect(() => {
		setFilter(
			allUsers?.filter(
				(p) =>
					p?.invitationCode === databyEmail?.url ||
					(Array.isArray(p?.assignedTo) &&
						p.assignedTo.some(
							(assignee) =>
								typeof assignee === "object" &&
								assignee?.email === databyEmail?.email,
						)),
			) ?? [],
		);
	}, [allUsers, databyEmail?.email, databyEmail?.url]);

	const [filtered, setFiltered] = useState();
	useEffect(() => {
		setFiltered(
			databyEmail?.role === ("Admin" || "Manager") ? allUsers : filter,
		);
	}, [allUsers, filter, databyEmail?.role]);
	let count = 1;
	const [search, setSearch] = React.useState("");
	const handleChange = (event) => {
		setSearch(event.target.value);
	};
	const [newList, setNewList] = React.useState([]);
	useEffect(() => {
		const searchs = filtered?.filter(
			(user) =>
				user?.name?.toLowerCase().includes(search.toLowerCase()) ||
				user?.email?.toLowerCase().includes(search.toLowerCase()) ||
				user?.phone?.toLowerCase().includes(search.toLowerCase()) ||
				/* user?.assignedTo?.toLowerCase().includes(search.toLowerCase()) || */
				user?.invitationCode?.toLowerCase().includes(search.toLowerCase()) ||
				user?.scheduleId?.toLowerCase().includes(search.toLowerCase()),
		);
		setNewList(searchs);
	}, [filtered, search]);

	const [perpage, setPerpage] = React.useState(5);
	const [newArray, setNewArray] = React.useState();
	const [page, setPage] = React.useState(1);
	const handleChanges = (event, value) => {
		setPage(value);
	};
	useEffect(() => {
		setNewArray(newList?.slice((page - 1) * perpage, page * perpage));
	}, [newList, page, perpage]);
	return (
		<>
			<Box sx={{ height: "90px", mx: 2 }}>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h4'>
					{userName} Schedules
				</Typography>
				<TextField
					size='small'
					sx={{ width: "100%", maxWidth: "550px" }}
					id='outlined-name'
					placeholder='Search by ID / Name / Email / Phone / Invite Code /Assigned To'
					value={search}
					onChange={handleChange}
				/>
			</Box>
			<Paper
				className='container'
				sx={{
					overflow: "auto",
					maxHeight: "calc(90vh - 90px)",
					maxWidth: "90vw",
					mx: 2,
				}}>
				<Box
					className='pagination'
					sx={{ display: "flex", justifyContent: "center" }}>
					<FormControl>
						<NativeSelect
							defaultValue={perpage}
							onChange={(e) => setPerpage(e.target.value)}>
							<option value={5}>5</option>
							<option value={10}>10</option>
							<option value={15}>15</option>
							<option value={20}>20</option>
							<option value={40}>40</option>
							<option value={50}>50</option>
						</NativeSelect>
					</FormControl>
					<Pagination
						sx={{
							display: "flex",
							justifyContent: "center !important",
						}}
						count={Math.ceil(newList?.length / perpage)}
						page={page}
						onChange={handleChanges}
						variant='outlined'
						shape='rounded'
					/>
				</Box>
				<Table size='small' stickyHeader aria-label='sticky table'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='left'>No</TableCell>
							<TableCell align='left'>ID</TableCell>
							<TableCell align='left'>Type</TableCell>
							<TableCell align='left'>Submited On</TableCell>
							<TableCell align='left'>Scheduled For</TableCell>
							<TableCell align='left'>Product</TableCell>
							<TableCell align='left'>Asigned To</TableCell>
							<TableCell align='left'>Name</TableCell>
							<TableCell align='left'>Phone</TableCell>
							<TableCell align='left'>Invited By</TableCell>
							<TableCell align='left'>Status</TableCell>
							<TableCell align='center'>View</TableCell>
						</TableRow>
					</TableHead>
					{allUsers?.length > 0 ? (
						<TableBody sx={{ td: { py: 1 } }}>
							{newArray?.map((user, key) => (
								<TableRow
									key={key}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
									}}>
									<TableCell align='left'>{count++}</TableCell>
									<TableCell align='left'>{user?.scheduleId}</TableCell>
									<TableCell align='left'>{user?.scheduleType}</TableCell>
									<TableCell align='left'>{user?.submitTime}</TableCell>
									<TableCell align='left'>
										{setTime(user?.selectedDateTime)}
									</TableCell>
									<TableCell align='left'>
										{user?.selectedProduct?.name}
									</TableCell>
									<TableCell align='left'>
										<Typography variant='p'>
											{Array.isArray(user?.assignedTo)
												? user?.assignedTo?.map((assignee) => (
														<div key={assignee?.email}>
															({assignee?.url})&nbsp;
														</div>
												  ))
												: user?.assignedTo || "N/A"}
										</Typography>
									</TableCell>
									<TableCell align='left'>{user?.name}</TableCell>
									<TableCell align='left'>
										<a
											href={`tel:${user?.phoneNumber}`}
											className='textColor'
											style={{ textDecoration: "none" }}>
											{user?.phoneNumber}
										</a>
									</TableCell>
									<TableCell align='left'>{user?.invitationCode}</TableCell>
									<TableCell align='left'>
										<Typography variant='p'>{user?.status}</Typography>
									</TableCell>
									<TableCell align='center'>
										<Box sx={{ display: "flex", alignItems: "center" }}>
											<Button
												onClick={() => {
													setOpen(true);
													setUser(user);
												}}
												className='button border'
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<RemoveRedEyeIcon />
											</Button>
											<Button
												onClick={() => {
													setOpenNote(true);
													setUser(user);
												}}
												className='button border'
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<NoteAddIcon />
											</Button>
										</Box>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : (
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
							</TableRow>
						</TableHead>
					)}
				</Table>
			</Paper>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!allUsers}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && <SingleUser open={open} setOpen={setOpen} user={user} />}
			{openNote && (
				<Note openNote={openNote} setOpenNote={setOpenNote} user={user} />
			)}
		</>
	);
};

export default FilteredOpp;
