import { Box, Button, MenuItem, Select } from "@mui/material";
import axios from "axios";
import React from "react";
import Swal from "sweetalert2";

const StatusChange = ({ setSubmitting, user }) => {
	const [openStatus, setStatus] = React.useState(false);
	const handleClose = () => {
		setStatus(false);
	};

	const handleOpen = () => {
		setStatus(true);
	};
	return (
		<Box sx={{ display: "flex" }}>
			<Button
				sx={{ minWidth: "90px", py: 1, height: "40px", my: "auto" }}
				onClick={handleOpen}>
				{user?.status || "N/A"}
			</Button>
			<Select
				sx={{ visibility: "hidden", mr: -12 }}
				open={openStatus}
				onClose={handleClose}
				onOpen={handleOpen}
				onChange={(e) => {
					Swal.fire({
						title: "Are you sure?",
						text:
							e.target.value === "Closed" &&
							"You won't be able to revert this!",
						icon: "warning",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: `Yes, Change Status!`,
					}).then((result) => {
						if (result.isConfirmed) {
							if (e.target.value === "Closed") {
								setSubmitting(true);
								axios
									.put(
										`${process.env.REACT_APP_API_PATH}/RefferelsStatusClosed/${user?._id}`,
										{
											status: e.target.value,
											closedOn: new Date().toLocaleString("en-US", {
												timeZone: "America/Los_Angeles",
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
												hour: "2-digit",
												minute: "2-digit",
												second: "2-digit",
												timeZoneName: "short",
											}),
										},
									)
									.then(function (response) {
										setSubmitting(false);
										Swal.fire({
											icon: "success",
											title: "Successfully Updated",
											showConfirmButton: true,
											timer: 2500,
										});
									})
									.catch(function (error) {
										console.log(error);
									});
							} else {
								axios
									.put(
										`${process.env.REACT_APP_API_PATH}/RefferelsStatus/${user?._id}`,
										{
											status: e.target.value,
										},
									)
									.then(function (response) {
										setSubmitting(false);
										Swal.fire({
											icon: "success",
											title: "Successfully Updated",
											showConfirmButton: false,
											timer: 1500,
										});
									})
									.catch(function (error) {
										console.log(error);
									});
							}
						} else {
							setSubmitting(false);
						}
					});
				}}
				defaultValue={user?.status}>
				<MenuItem value={"Pending"}>Pending</MenuItem>
				<MenuItem value={"Closed"}>Close</MenuItem>
			</Select>
		</Box>
	);
};

export default StatusChange;
