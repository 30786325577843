import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
	apiKey: "AIzaSyDvT_kReypT5cQuKhwoyrFf5tg1GPiLbRs",
	authDomain: "america-solar.firebaseapp.com",
	projectId: "america-solar",
	storageBucket: "america-solar.appspot.com",
	messagingSenderId: "253479569736",
	appId: "1:253479569736:web:ab969d394b26e359ee0b7d",
	measurementId: "G-XS917M2MBE",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;
