import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Button,
	Grid,
	TextField,
	Box,
	Container,
	Backdrop,
	CircularProgress,
	Chip,
	Fade,
	Modal,
} from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	width: { md: "50vw", sm: "70vw", xs: "90vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
	textAlign: "center",
};

const CalanderEdit = ({ setState, open, setOpen, data }) => {
	const [startValue, setStartValue] = React.useState(new Date(data?.from));
	const [endValue, setEndValue] = React.useState(new Date(data?.to));
	const [color, setColor] = useState(data?.color);
	const [titles, setTitles] = useState(data?.title);
	const [submitting, setSubmitting] = useState(false);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			color: "",
			from: "",
			to: "",
			title: "",
			id: "",
		},
	});

	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/calanders/${data?._id}`)
			.then((res) => res.json())
			.then((data) => {
				reset(data);
			});
	}, [data, reset, submitting]);
	const onSubmit = ({ title }) => {
		setSubmitting(true);
		setState(true);
		const datas = {
			color: color,
			from: startValue?.toISOString().split("Z")[0],
			to: endValue?.toISOString().split("Z")[0],
			title,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
			id: `calander${Math.floor(100000 + Math.random() * 900000)}`,
		};
		console.log(datas);
		axios
			.put(`${process.env.REACT_APP_API_PATH}/calanders/${data?._id}`, datas)
			.then(function (response) {
				setSubmitting(false);
				setState(false);
				setOpen(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: true,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<Container>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={open}>
					<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
						<CancelIcon
							onClick={() => setOpen(false)}
							className='textColor'
							sx={{
								position: "fixed",
								top: "5px",
								right: "5px",
								cursor: "pointer",
							}}
						/>
						<Typography
							className='textColor'
							sx={{ fontWeight: "bold", mb: 1 }}
							variant='h4'>
							Post New Event
						</Typography>

						<Box
							display='flex'
							flexDirection='column'
							alignItems='center'
							sx={{ mx: "auto" }}>
							<form onSubmit={handleSubmit(onSubmit)} method='post'>
								<Grid container spacing={2}>
									<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
										<TextField
											sx={{ width: "100%" }}
											id='outlined-basic'
											name='title'
											multiline
											rows={5}
											label='Event Decription'
											value={titles}
											onChange={(e) => {
												setTitles(e.target.value);
											}}
											{...register("title", { required: true })}
										/>
									</Grid>
									<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DateTimePicker
												disablePast
												renderInput={(props) => (
													<TextField fullWidth {...props} />
												)}
												label='Start Date Time'
												value={startValue}
												onChange={(newValue) => {
													setStartValue(newValue);
												}}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DateTimePicker
												renderInput={(props) => (
													<TextField fullWidth {...props} />
												)}
												label='End Date Time'
												value={endValue}
												onChange={(newValue) => {
													setEndValue(newValue);
												}}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
										<Chip label='Click below to select color' sx={{ mb: 1 }} />
										<input
											style={{ width: "100%", height: "57px" }}
											type='color'
											value={color}
											onChange={(e) => setColor(e.target.value)}
										/>
										<input
											style={{
												width: "100%",
												height: "45px",
												padding: "0 1rem",
											}}
											type='text'
											value={color}
											onChange={(e) => setColor(e.target.value)}
										/>
									</Grid>
									<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
										<Button
											className='sendButton'
											type='submit'
											variant='contained'
											sx={{
												width: "100%",
												mb: 2,
												px: 3,
												fontWeight: "bold",
												borderRadius: "25px",
											}}>
											Update
										</Button>
									</Grid>
								</Grid>
							</form>
						</Box>

						<Backdrop
							sx={{
								color: "#fff",
								zIndex: (theme) => theme.zIndex.drawer + 1,
							}}
							open={submitting}>
							<CircularProgress color='inherit' />
						</Backdrop>
					</Box>
				</Fade>
			</Modal>
		</Container>
	);
};

export default CalanderEdit;
