import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import Switch from "react-switch";
import axios from "axios";
import Swal from "sweetalert2";
import GetAuth from "../../../GetAuth";

const Paypal = ({ data }) => {
	const [user] = GetAuth();
	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			paypalBank: "",
			paypalEmail: "",
			paypalPhone: "",
			paypalUsername: "",
		},
	});
	const [submitting, setSubmitting] = useState(false);

	const [datas, setDatas] = useState();
	const [checked, setChecked] = React.useState(
		datas?.defaultMethod === "Paypal" ? true : false,
	);
	React.useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_API_PATH}/paymentInfo/${
					data?.url || data?.user
				}`,
			)
			.then((res) => {
				reset(res.data?.paypalInfo);
				setDatas(res.data);
			});
	}, [reset, data, submitting, checked]);
	const onSubmit = (data) => {
		setSubmitting(true);
		const info = data;
		const submitTime = new Date().toLocaleString("en-US", {
			timeZone: "America/Los_Angeles",
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			timeZoneName: "short",
		});
		axios
			.put(
				`${process.env.REACT_APP_API_PATH}/paymentInfoPaypal/${datas?._id}`,
				{
					info,
					submitTime,
				},
			)
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Updated",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleChange = (nextChecked) => {
		setSubmitting(true);
		axios
			.put(`${process.env.REACT_APP_API_PATH}/default/${datas?._id}`, {
				defaultMethod: nextChecked === true && "Paypal",
			})
			.then(function (response) {
				setSubmitting(false);
				setChecked(nextChecked);
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	return (
		<Container>
			<p className='textColor'>Last Updated: {datas?.submitTime}</p>
			{data?.email === user?.email && (
				<Box
					className='borderColor'
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						border: "3px solid",
						width: "200px",
						mx: "auto",
						py: 0.7,
						mb: 1,
					}}>
					<Typography
						className='textColor'
						sx={{ fontWeight: "bold", mr: 1 }}
						variant='body1'>
						Set As Default
					</Typography>
					{datas && (
						<Switch
							onChange={handleChange}
							checked={datas?.defaultMethod === "Paypal" ? true : false}
							className='react-switch'
						/>
					)}
				</Box>
			)}

			<img src='/paypal.png' alt='' style={{ width: "50px", height: "50px" }} />
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold", mb: 1 }}
				variant='h4'>
				Paypal
			</Typography>

			<form onSubmit={handleSubmit(onSubmit)} method='post'>
				<Grid container spacing={2}>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ width: "100%" }}
							id='outlined-basic'
							type={"email"}
							name='Email Account'
							label='Email Account'
							{...register("paypalEmail", { required: true })}
						/>
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ width: "100%" }}
							id='outlined-basic'
							type={"text"}
							name='Paypal Username'
							label='Paypal Username'
							{...register("paypalUsername", { required: true })}
						/>
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ width: "100%" }}
							id='outlined-basic'
							type={"tel"}
							name='Phone Number'
							label='Phone Number'
							placeholder='+1-212-456-7890'
							{...register("paypalPhone", { required: true })}
						/>
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ width: "100%" }}
							id='outlined-basic'
							type='text'
							name='Connected Bank Acc Or Card'
							label='Connected Bank Acc Or Card'
							{...register("paypalBank", { required: true })}
						/>
					</Grid>
					{data?.email === user?.email && (
						<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
							<Button
								type='submit'
								variant='contained'
								className='button border'
								sx={{
									my: 1,
									py: 0.5,
									width: "150px",
									border: "2px solid",
									backgroundColor: "transparent",
								}}>
								Submit
							</Button>
						</Grid>
					)}
				</Grid>
			</form>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting || !datas}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Container>
	);
};

export default Paypal;
