import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SingleUser from "./SingleUser";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Note from "./Note";
import { useAPI } from "../../../../apiContext";
import StatusChange from "./StatusChange";
import AssignedChange from "./AssignedChange";
import { DataGrid } from "@mui/x-data-grid";
import DatePicker from "react-datepicker";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import DeleteFunction from "./DeleteFunction";
import Search from "../../../../Pagination/Search";
import Pagination from "../../../../Pagination/Pagination";
import ReSchedule from "./ReSchedule";

const InvitedUser = () => {
	const { databyEmail, SetMenuItems } = useAPI();
	const [open, setOpen] = useState(false);
	const [openNote, setOpenNote] = useState(false);
	const [users, setUsers] = useState();
	console.log("🚀 ~ InvitedUser ~ users:", users?.length);
	const [user, setUser] = useState();
	const [view, setView] = useState(localStorage.getItem("view") || "calendar");
	const [submitting, setSubmitting] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");
	// Function to handle page change
	const handlePageChange = (newPage, newItemsPerPage) => {
		setCurrentPage(newPage);
		if (newItemsPerPage !== undefined) {
			setItemsPerPage(newItemsPerPage);
		}
	};
	function convertDateFormat(inputDateString) {
		const [dateString, timeZoneString] = inputDateString?.split("-") ?? [];

		// Parse the date string
		const inputDate = new Date(dateString);

		// Convert to desired format
		const formattedDate = inputDate.toLocaleString("en-US", {
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			hour12: true,
			timeZoneName: "short",
			timeZone: timeZoneString?.trim(),
		});

		return formattedDate;
	}

	const [activeStartDate, setActiveStartDate] = useState(new Date());

	const [filtered, setFiltered] = useState();
	const handleActiveStartDateChange = ({ activeStartDate }) => {
		setActiveStartDate(activeStartDate);
	};

	const [dateOpen, setDateOpen] = useState(false);

	const getMonthAndYear = (date) => {
		const month = new Date(date).getMonth() + 1;
		const year = new Date(date).getFullYear();
		return { month, year };
	};
	useEffect(() => {
		const { month, year } = getMonthAndYear(activeStartDate);
		setNowMonthYear({
			month: month,
			year: year,
		});
	}, [activeStartDate]);

	const [nowMonthYear, setNowMonthYear] = useState({
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear(),
	});

	useEffect(() => {
		fetch(
			view === "calendar"
				? `${process.env.REACT_APP_API_PATH}/invitatedusersSubmitTime/${nowMonthYear?.month}/${nowMonthYear?.year}`
				: `${process.env.REACT_APP_API_PATH}/invitatedusers?page=${currentPage}&perPage=${itemsPerPage}${searchQuery}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setUsers(data.data);
				setTotalItems(data?.pagination?.total || 0);
			});
	}, [
		currentPage,
		itemsPerPage,
		nowMonthYear?.month,
		nowMonthYear?.year,
		searchQuery,
		submitting,
		view,
	]);

	const [api, setApi] = useState();
	const [name, setName] = useState();
	const [selectUsers, setSelectUsers] = useState();
	useEffect(() => {
		/* 
		if (databyEmail?.role === "Admin") {
			setApi("regionalTeam");
		} else  */ if (databyEmail?.role === "Regional") {
			setApi("regionalTeam");
		} else if (databyEmail?.role === "Manager") {
			setApi("managerTeam");
		} else if (databyEmail?.role === "Team-Lead") {
			setApi("teamLeadTeam");
		} else if (databyEmail?.role === "Trainer") {
			setApi("trainerTeam");
		}
	}, [databyEmail]);
	useEffect(() => {
		if (name && api) {
			fetch(`${process.env.REACT_APP_API_PATH}/${api}/${name?.name}`)
				.then((res) => res.json())
				.then((data) => {
					setSelectUsers(data);
				});
		}
	}, [api, name]);

	const [filter, setFilter] = useState();
	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_PATH}/findUserTeam/${api}/${databyEmail?.url}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setName(data);
			});
	}, [api, databyEmail]);

	useEffect(() => {
		setFilter(
			users?.filter(
				(p) =>
					p?.invitationCode === databyEmail?.url ||
					selectUsers?.find((n) => n === p?.invitationCode) ||
					(Array.isArray(p?.assignedTo) &&
						p.assignedTo.some(
							(assignee) =>
								typeof assignee === "object" &&
								assignee?.email === databyEmail?.email,
						)),
			) ?? [],
		);
	}, [databyEmail, selectUsers, users]);

	function setTime(time) {
		const previousZone = new Date(time?.split("-")[0]).toLocaleString("en-US", {
			timeZone: time?.split("-")[1],
		});
		const localZone = new Date(previousZone).toLocaleString("en-US", {
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		});
		return localZone;
	}

	useEffect(() => {
		setFiltered(databyEmail?.role === "Admin" ? users : filter);
	}, [filter, databyEmail?.role, users]);

	const getAssigned = (assignedTo) => {
		const assigneeList = Array.isArray(assignedTo)
			? assignedTo.map((assignee) => (
				<div key={assignee?.email}>({assignee?.url})&nbsp;</div>
			))
			: null;

		return assigneeList;
	};
	const [assingChange, setAssingChange] = useState(false);
	const columns = [
		{
			field: "scheduleId",
			headerName: "ID",
			minWidth: 150,
		},
		{
			field: "scheduleType",
			headerName: "Type",
			minWidth: 50,
		},
		{
			field: "submitTime",
			headerName: "Submited On",
			minWidth: 250,
		},
		{
			field: "selectedDateTime",
			headerName: "Scheduled For",
			minWidth: 200,
			valueGetter: (params) => params?.row?.selectedDateTime,
			renderCell: (params) => {
				return (
					<span
						style={{ cursor: "pointer", color: "red" }}
						onClick={() => {
							setDateOpen(true);
							setUser(params?.row);
						}}>
						{setTime(params?.row?.selectedDateTime)}
					</span>
				);
			},
		},
		{
			field: "selectedProduct",
			headerName: "Product",
			minWidth: 200,
			valueGetter: (params) => params?.row?.selectedProduct?.name,
			renderCell: (params) => {
				return params?.row?.selectedProduct?.name;
			},
		},
		{
			field: "selectedProduct",
			headerName: "Potential Commission",
			minWidth: 200,
			valueGetter: (params) =>
				params?.row?.selectedProduct?.potentialCommission,
			renderCell: (params) => {
				return params?.row?.selectedProduct?.potentialCommission;
			},
		},
		{
			field: "assignedToDetail",
			headerName: "Asigned To",
			minWidth: 300,
			valueGetter: (params) => params?.row?.assignedTo,
			renderCell: (params) => {
				return (
					<>
						{databyEmail && databyEmail?.role !== "Agent" ? (
							<Button
								variant='contained'
								color='primary'
								onClick={() =>
									setAssingChange({
										user: params?.row,
										setSubmitting,
									})
								}>
								{getAssigned(params?.row?.assignedTo)?.length > 0
									? getAssigned(params?.row?.assignedTo)
									: "N/A"}
							</Button>
						) : (
							<>
								{getAssigned(params?.row?.assignedTo)?.length > 0
									? getAssigned(params?.row?.assignedTo)
									: "N/A"}
							</>
						)}
					</>
				);
			},
		},
		{
			field: "assignedTo",
			headerName: "Assigned To Old Data",
			minWidth: 250,
		},
		{
			field: "name",
			headerName: "Name",
			minWidth: 170,
		},
		{
			field: "phoneNumber",
			headerName: "Phone",
			minWidth: 140,
			valueGetter: (params) => params?.row?.phoneNumber,
			renderCell: (params) => {
				return (
					<a
						href={`tel:${params?.row?.phoneNumber}`}
						className='textColor'
						style={{ textDecoration: "none" }}>
						{params?.row?.phoneNumber}
					</a>
				);
			},
		},
		{
			field: "invitationCode",
			headerName: "Invited By",
			minWidth: 130,
		},
		{
			field: "status",
			headerName: "Status",
			minWidth: 230,
			valueGetter: (params) => params?.row?.status,
			renderCell: (params) => {
				return params?.row && params?.row?.status === "Closed" ? (
					<Typography variant='p'>
						{SetMenuItems(params?.row?.status) ?? "N/A"}
					</Typography>
				) : (
					<>
						{(databyEmail && databyEmail?.role !== "Agent") ||
							params?.row?.invitationCode === databyEmail?.url ? (
							<StatusChange
								user={params?.row}
								setSubmitting={setSubmitting}
								databyEmail={databyEmail}
								SetMenuItems={SetMenuItems}
							/>
						) : (
							<Typography variant='p'>
								{SetMenuItems(params?.row?.status) ?? "N/A"}
							</Typography>
						)}
					</>
				);
			},
		},
		{
			field: "view",
			headerName: "View",
			minWidth: 240,
			filterable: false,
			renderCell: (params) => {
				return (
					<Box sx={{ display: "flex" }}>
						<Button
							onClick={() => {
								setOpen(true);
								setUser(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<RemoveRedEyeIcon />
						</Button>
						<Button
							onClick={() => {
								setOpenNote(true);
								setUser(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<NoteAddIcon />
						</Button>
						<DeleteFunction
							setSubmitting={setSubmitting}
							databyEmail={databyEmail}
							params={params}
						/>
					</Box>
				);
			},
		},
	];
	const [selectedDate, setSelectedDate] = useState(null);
	const handleDateSelect = (date) => {
		setSelectedDate(date);
	};

	const filteredTimes = filtered?.filter((item) => {
		const date = convertDateFormat(item?.selectedDateTime);
		const selected = new Date(selectedDate);
		return new Date(date)?.toDateString() === selected.toDateString();
	});

	const [selectedStartDate, setSelectedStartDate] = useState(null);
	const [selectedEndDate, setSelectedEndDate] = useState(null);

	const highlightDates = filtered?.map((item) =>
		convertDateFormat(item?.selectedDateTime),
	);
	const handleStartDateSelect = (date) => {
		setSelectedStartDate(date);
	};

	const handleEndDateSelect = (date) => {
		setSelectedEndDate(date);
	};

	const filterEntriesBetweenDates = () => {
		if (selectedStartDate && selectedEndDate) {
			const startDate = new Date(selectedStartDate);
			const endDate = new Date(selectedEndDate);
			return filtered.filter((item) => {
				const entryDate = convertDateFormat(item?.selectedDateTime);
				return (
					new Date(entryDate) >= startDate && new Date(entryDate) <= endDate
				);
			});
		}
		return [];
	};

	const filteredEntries = filterEntriesBetweenDates();
	const [reset, setReset] = useState(false);
	useEffect(() => {
		if (selectedStartDate && selectedEndDate) {
			setSelectedDate(null);
		}
		if (selectedDate) {
			setSelectedStartDate(null);
			setSelectedEndDate(null);
		}
		if (reset) {
			setSelectedDate(null);
			setSelectedStartDate(null);
			setSelectedEndDate(null);
		}
	}, [selectedDate, selectedEndDate, selectedStartDate, reset]);
	const markedDates = filtered?.map((timeObject) => timeObject);

	const handleDateClick = (date) => {
		setSelectedDate(date);
		localStorage.setItem("view", "list");
		setView("list");
	};

	const tileClassName = ({ date }) => {
		return markedDates?.some((markedDate) =>
			dayjs(convertDateFormat(markedDate?.selectedDateTime)).isSame(
				date,
				"day",
			),
		)
			? "highlighted"
			: "blocked";
	};
	const tileContent = ({ date, view }) => {
		return (
			<span
				style={{
					fontSize: "0.9rem",
				}}>
				<br />
				{filtered?.filter((user) =>
					dayjs(convertDateFormat(user?.selectedDateTime)).isSame(date, "day"),
				).length > 0
					? `(${filtered?.filter((user) =>
						dayjs(convertDateFormat(user?.selectedDateTime)).isSame(
							date,
							"day",
						),
					).length
					})`
					: null}
			</span>
		);
	};
	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Box>
				<p>Default View</p>
				<button
					onClick={() => {
						localStorage.setItem("view", "calendar");
						setView("calendar");
						setNowMonthYear({
							month: new Date().getMonth() + 1,
							year: new Date().getFullYear(),
						});
						setUsers([]);
						setTotalItems(0);
					}}
					style={{
						border: "1px solid",
						backgroundColor: view === "calendar" ? "green" : "transparent",
						color: view === "calendar" ? "white" : "black",
						pointerEvents: view === "calendar" ? "none" : "auto",
					}}>
					Calendar
				</button>
				<button
					onClick={() => {
						localStorage.setItem("view", "list");
						setView("list");
						setNowMonthYear({
							month: new Date().getMonth() + 1,
							year: new Date().getFullYear(),
						});
						setUsers([]);
						setTotalItems(0);
					}}
					style={{
						backgroundColor: view === "list" ? "green" : "transparent",
						color: view === "list" ? "white" : "black",
						border: "1px solid",
						pointerEvents: view === "list" ? "none" : "auto",
					}}>
					List
				</button>
			</Box>
			{view === "list" ? (
				<Box>
					<Box>
						<p>Filter by Single Date</p>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}>
							<DatePicker
								selected={selectedDate}
								onChange={handleDateSelect}
								highlightDates={highlightDates}
								placeholderText='Select Date'
							/>{" "}
							<button
								onClick={() => setReset(!reset)}
								className='borderColor'
								style={{
									border: "1px solid",
								}}>
								Reset
							</button>
						</Box>
					</Box>
					<Box>
						<p>Filter by Date Range</p>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}>
							<DatePicker
								selected={selectedStartDate}
								onChange={handleStartDateSelect}
								placeholderText='Select Start Date'
							/>{" "}
							<DatePicker
								selected={selectedEndDate}
								onChange={handleEndDateSelect}
								placeholderText='Select End Date'
							/>
							<button
								className='borderColor'
								style={{
									border: "1px solid",
								}}
								onClick={() => setReset(!reset)}>
								Reset
							</button>
						</Box>
					</Box>
					<Typography
						className='textColor'
						sx={{ fontWeight: "bold" }}
						variant='h4'>
						Opportunities
					</Typography>
					{filtered && (
						<Box sx={{ width: "100%", height: "85vh" }}>
							<DataGrid
								rows={
									selectedDate
										? filteredTimes
										: selectedStartDate && selectedEndDate
											? filteredEntries
											: filtered
								}
								getRowId={(row) => row?._id}
								columns={columns}
								components={{
									Toolbar: () => (
										<Search
											setSearchQuery={setSearchQuery}
											searchFields={[
												"name",
												"phoneNumber",
												"email",
												"scheduleId",
												"scheduleType",
												"selectedDateTime",
												"status",
												"invitationCode",
												"submitTime",
												"guestEmails",
												"dealClosed",
											]}
										/>
									),
									Footer: () => (
										<Pagination
											totalItems={totalItems}
											currentPage={currentPage}
											itemsPerPage={itemsPerPage}
											onPageChange={handlePageChange}
										/>
									),
								}}
								disableSelectionOnClick
							/>
						</Box>
					)}
				</Box>
			) : (
				<Box
					sx={{
						mt: 2,

						"& .react-calendar .react-calendar__viewContainer button": {
							minHeight: {
								md: "6rem",
							},
							border: "1px solid #dad9e5",
						},
					}}>
					<Calendar
						value={selectedDate}
						onClickDay={handleDateClick}
						tileClassName={tileClassName}
						tileContent={({ date, view }) => tileContent({ date, view })}
						onActiveStartDateChange={handleActiveStartDateChange}
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!users}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{open && (
				<SingleUser
					setSubmitting={setSubmitting}
					open={open}
					setOpen={setOpen}
					user={user}
				/>
			)}
			{openNote && (
				<Note openNote={openNote} setOpenNote={setOpenNote} user={user} />
			)}
			{assingChange && (
				<AssignedChange
					assingChange={assingChange}
					setAssingChange={setAssingChange}
				/>
			)}
			{dateOpen && (
				<ReSchedule dateOpen={dateOpen} setDateOpen={setDateOpen} user={user} />
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !databyEmail}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default InvitedUser;
