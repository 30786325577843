import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paypal from "./Paypal";
import Venmo from "./Venmo";
import Cashapp from "./Cashapp";
import { Container, Typography } from "@mui/material";
import WireTransfer from "./WireTransfer";
import W3Form from "./W3Form";
import { useAPI } from "../../../apiContext";
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

export default function AddPaymentDetails() {
	const { databyEmail } = useAPI();
	const theme = useTheme();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};

	return (
		<Container>
			<W3Form data={databyEmail} />
			<Typography
				className='textColor'
				sx={{ mt: 2, fontWeight: "bold" }}
				variant='h4'
				gutterBottom>
				Choose a payment method
			</Typography>
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor='secondary'
				textColor='inherit'
				variant='fullWidth'
				aria-label='full width tabs example'>
				<Tab label='Paypal' {...a11yProps(0)} />
				<Tab label='Wire Transfer' {...a11yProps(1)} />
				<Tab label='Venmo' {...a11yProps(2)} />
				<Tab label='Chash App' {...a11yProps(3)} />
			</Tabs>
			<SwipeableViews
				axis={theme.direction === "rtl" ? "x-reverse" : "x"}
				index={value}
				onChangeIndex={handleChangeIndex}>
				<TabPanel value={value} index={0} dir={theme.direction}>
					<Paypal data={databyEmail} />
				</TabPanel>
				<TabPanel value={value} index={1} dir={theme.direction}>
					<WireTransfer data={databyEmail} />
				</TabPanel>
				<TabPanel value={value} index={2} dir={theme.direction}>
					<Venmo data={databyEmail} />
				</TabPanel>
				<TabPanel value={value} index={3} dir={theme.direction}>
					<Cashapp data={databyEmail} />
				</TabPanel>
			</SwipeableViews>
		</Container>
	);
}
