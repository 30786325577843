import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const ManageQuestions = () => {
	const { id } = useParams();
	const [deleted, setDeleted] = useState(false);
	const [product, setProduct] = useState();
	const [question, setQuestion] = useState();
	const [submitting, setSubmitting] = useState(false);
	/* const [user, setUser] = useState("Customer"); */
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/findproducts/${id}`)
			.then((res) => res.json())
			.then((data) => {
				setProduct(data);
			});
	}, [deleted, id, submitting]);
	const handleDelete = (inShort) => {
		setDeleted(true);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				axios
					.put(
						`${process.env.REACT_APP_API_PATH}/deleteQuestions/${id}/${inShort}`,
					)
					.then(function (response) {
						Swal.fire("Deleted!", "That question has been deleted.", "success");
						setDeleted(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};

	const [checked, setChecked] = React.useState();
	const handleChange = (event) => {
		setChecked(event.target.checked);
		setSubmitting(true);
		axios
			.put(`${process.env.REACT_APP_API_PATH}/productBill/${id}`, {
				billPhoto: event.target.checked,
			})
			.then(function (response) {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Updated",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const columns = [
		{
			field: "question",
			headerName: "Questions",
			flex: 1,
		},
		{
			field: "Action",
			headerName: "Action",
			filterable: false,
			minWidth: 150,
			renderCell: (params) => {
				return (
					<Button
						className='button border'
						onClick={() => handleDelete(params?.row?.inShort)}
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'>
						<DeleteIcon />
					</Button>
				);
			},
		},
	];

	const [open, setOpen] = useState(false);

	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h6'>
				${product?.name} questions
			</Typography>
			<Button
				onClick={() => (open ? setOpen(false) : setOpen(true))}
				variant='contained'
				type='submit'
				sx={{
					mb: 1,
					px: 3,
					fontWeight: "bold",
					borderRadius: "25px",
					backgroundColor: open && "red !important",
				}}>
				{open ? "Close" : "Add New"}
			</Button>{" "}
			{open && (
				<Grid container spacing={2}>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						{/* <FormControl variant='filled' fullWidth sx={{ mb: 1.5 }}>
						<InputLabel>Ques For?</InputLabel>
						<NativeSelect
							defaultValue={"Customer"}
							onChange={(e) => setUser(e.target.value)}>
							<option value={"Agent"}>Agent</option>
							<option value={"Customer"}>Customer</option>
						</NativeSelect>
					</FormControl>*/}
						<TextField
							sx={{ width: "100%" }}
							multiline
							rows={4}
							id='outlined-basic'
							name='Question'
							label='Question'
							onChange={(e) => setQuestion(e.target.value)}
						/>
					</Grid>
					<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
						<Button
							onClick={() => {
								setSubmitting(true);
								const data = {
									question: question,
									/* quesFor: user, */
									submitTime: new Date().toLocaleString("en-US", {
										timeZone: "America/Los_Angeles",
										year: "numeric",
										month: "2-digit",
										day: "2-digit",
										hour: "2-digit",
										minute: "2-digit",
										second: "2-digit",
										timeZoneName: "short",
									}),
									inShort: "ques" + Math.floor(100000 + Math.random() * 900000),
								};
								console.log(data);
								axios
									.put(
										`${process.env.REACT_APP_API_PATH}/productsquestions/${id}`,
										data,
									)
									.then(function (response) {
										setSubmitting(false);
										Swal.fire({
											icon: "success",
											title: "Successfully Added",
											showConfirmButton: true,
											timer: 2500,
										});
									})
									.catch(function (error) {
										console.log(error);
									});
							}}
							className='sendButton'
							type='submit'
							variant='contained'
							sx={{
								width: "100%",
								mb: 2,
								px: 3,
								fontWeight: "bold",
								borderRadius: "25px",
							}}>
							Post
						</Button>
					</Grid>
				</Grid>
			)}{" "}
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Typography className='textColor' sx={{ mr: 1, fontWeight: "bold" }}>
					Bill Photo
				</Typography>
				<input
					type='checkbox'
					checked={checked || product?.billPhoto}
					onChange={handleChange}
				/>
			</Box>
			{product?.questions && (
				<Box sx={{ width: "100%", height: "70vh" }}>
					<DataGrid
						rows={product?.questions}
						getRowId={(row) => row?.inShort}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}
			{/* 	<>
				<Paper
					className='container'
					sx={{ overflow: "auto", maxHeight: "85vh", maxWidth: "90vw", m: 2 }}>
					<Table size='small' stickyHeader aria-label='sticky table'>
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow className='billCheck'>
								<TableCell align='center' colSpan={3}>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='h4'>
										${product?.name} questions
									</Typography>
									<Typography className='textColor'>
										Last Added in {product?.questions?.[0]?.submitTime || "N/A"}
									</Typography>

									<Box sx={{ display: "flex", justifyContent: "center" }}>
										<Typography
											className='textColor'
											sx={{ mr: 1, fontWeight: "bold" }}>
											Bill Photo
										</Typography>
										<input
											type='checkbox'
											checked={checked || product?.billPhoto}
											onChange={handleChange}
										/>
									</Box>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>Questions</TableCell>
								{/* <TableCell align='left'>Question For?</TableCell> *
								<TableCell align='center'>Action</TableCell>
							</TableRow>
						</TableHead>
						{product?.questions?.length > 0 ? (
							<TableBody sx={{ td: { py: 1 } }}>
								{product?.questions?.reverse()?.map((question, key) => (
									<TableRow
										key={key}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}>
										<TableCell align='left'>{count++}</TableCell>
										<TableCell align='left'>{question?.question}</TableCell>
										{/* <TableCell align='left'>{question?.quesFor}</TableCell> *
										<TableCell align='center'>
											<Button
												className='button border'
												onClick={() => handleDelete(question?.inShort)}
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						) : (
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='left'>N/A</TableCell>
									<TableCell align='center'>N/A</TableCell>
								</TableRow>
							</TableHead>
						)}
					</Table>
				</Paper>
			</> */}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !product}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default ManageQuestions;
