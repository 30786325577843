import React, { useEffect, useState } from "react";
import {
	Typography,
	CircularProgress,
	Backdrop,
	TextField,
	Button,
} from "@mui/material";
import { Box } from "@mui/system";
import Swal from "sweetalert2";
import axios from "axios";
import DoneIcon from "@mui/icons-material/Done";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Note from "./Note";
import NoteAboutRecruter from "./NoteAboutRecruter";
import Payment from "./Payment";
import { useAPI } from "../../../../apiContext";
import { DataGrid } from "@mui/x-data-grid";
import Search from "../../../../Pagination/Search";
import Pagination from "../../../../Pagination/Pagination";

const AgentsPayments = () => {
	const [sells, setSells] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");
	const [filtered, setFiltered] = useState("");
	// Function to handle page change
	const handlePageChange = (newPage, newItemsPerPage) => {
		setCurrentPage(newPage);
		if (newItemsPerPage !== undefined) {
			setItemsPerPage(newItemsPerPage);
		}
	};
	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_PATH}/successfullSells?page=${currentPage}&perPage=${itemsPerPage}&filtered=${filtered}${searchQuery}`,
		)
			.then((res) => res.json())
			.then((data) => {
				setSells(data?.data);
				setTotalItems(data.pagination.total);
			});
	}, [currentPage, itemsPerPage, searchQuery, submitting, filtered]);

	const { databyEmail } = useAPI();
	const [amounts, setAmounts] = useState();
	const [openNote, setOpenNote] = useState(false);
	const [openRecruterNote, setOpenRecruterNote] = useState(false);
	const [datas, setDatas] = useState();
	const [pay, setPay] = useState(false);
	let inviterTotal = 0;
	sells?.forEach((element) => {
		inviterTotal += parseFloat(element?.paidAmount || 0);
	});
	let inviterRecruterTotal = 0;
	sells?.forEach((element) => {
		inviterRecruterTotal += parseFloat(element?.recruterPaidAmount || 0);
	});

	const columns = [
		{
			field: "product",
			headerName: "Product",
			minWidth: 250,
			valueGetter: (params) => params?.row?.product?.name,
			renderCell: (params) => {
				return params?.row?.product?.name;
			},
		},
		{
			field: "potentialCommission",
			headerName: "Potential Commission",
			minWidth: 150,
			valueGetter: (params) => params?.row?.product?.potentialCommission,
			renderCell: (params) => {
				return params?.row?.product?.potentialCommission;
			},
		},
		{
			field: "customer",
			headerName: "Customer",
			minWidth: 200,
		},
		{
			field: "customerPhone",
			headerName: "Customer Phone",
			minWidth: 150,
		},
		{
			field: "invitedBy",
			headerName: "Invited By",
			minWidth: 100,
		},
		{
			field: "assignedTo",
			headerName: "Assigned To",
			minWidth: 300,
			valueGetter: (params) => params?.row?.assignedTo,
			renderCell: (params) => {
				const assignedTo = params?.row?.assignedTo;
				const assigneeList = Array.isArray(assignedTo)
					? assignedTo.map((assignee) => (
							<div key={assignee?.email}>({assignee?.url})&nbsp;</div>
					  ))
					: null;

				return assigneeList;
			},
		},
		{
			field: "soldOn",
			headerName: "Closed On",
			minWidth: 220,
		},
		{
			field: "CommissionAmount",
			headerName: "Commission Amount",
			minWidth: 170,
			valueGetter: (params) => params?.row?.paidAmount,
			renderCell: (params) => {
				return params?.row?.paidStatus !== "Paid" ? (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
						}}>
						<TextField
							size='small'
							sx={{ width: "70px" }}
							fullWidth
							label='$$$'
							type='number'
							defaultValue={params?.row?.paidAmount}
							onChange={(e) => setAmounts(e.target.value)}
						/>
						<Button
							className='border'
							onClick={() => {
								setSubmitting(true);
								axios
									.put(
										`${process.env.REACT_APP_API_PATH}/successfullSellsAmoutSet/${params?.row?._id}`,
										{ amount: amounts },
									)
									.then(function (response) {
										setSubmitting(false);
										Swal.fire({
											icon: "success",
											title: "Successfully Updated",
											showConfirmButton: true,
											timer: 2500,
										});
									})
									.catch(function (error) {
										console.log(error);
									});
							}}
							variant='contained'
							sx={{ width: "10px !important", ml: 0.2 }}>
							<DoneIcon />
						</Button>
					</Box>
				) : (
					<Typography variant='p'>${params?.row?.paidAmount}</Typography>
				);
			},
		},
		{
			field: "Status",
			headerName: "Status",
			minWidth: 75,
			valueGetter: (params) => params?.row?.paidStatus,
			renderCell: (params) => {
				return params?.row?.paidStatus === "Not Paid Yet" ? (
					<Button
						onClick={() => {
							setSubmitting(true);
							Swal.fire({
								title: "Are you sure?",
								text: "You won't be able to revert this!",
								icon: "warning",
								showCancelButton: true,
								confirmButtonColor: "#3085d6",
								cancelButtonColor: "#d33",
								confirmButtonText: `Yes, Marked As Paid`,
							}).then((result) => {
								if (result.isConfirmed) {
									axios
										.put(
											`${process.env.REACT_APP_API_PATH}/successfullSellsPaidStatus/${params?.row?._id}`,
											{
												status: "Paid",
												time: new Date().toLocaleString("en-US", {
													timeZone: "America/Los_Angeles",
													year: "numeric",
													month: "2-digit",
													day: "2-digit",
													hour: "2-digit",
													minute: "2-digit",
													second: "2-digit",
													timeZoneName: "short",
												}),
											},
										)
										.then(function (response) {
											setSubmitting(false);
											Swal.fire({
												icon: "success",
												title: "Successfully Marked As Paid",
												showConfirmButton: true,
												timer: 2500,
											});
										})
										.catch(function (error) {
											console.log(error);
										});
								}
							});
						}}
						className='sendButton'
						type='submit'
						variant='contained'
						sx={{
							minWidth: "150px",
							borderRadius: "25px",
						}}>
						Mark as Paid
					</Button>
				) : (
					<Typography variant='p'>
						{params?.row?.paidStatus || "N/A"}
					</Typography>
				);
			},
		},
		{
			field: "paidOn",
			headerName: "Paid On",
			minWidth: 220,
		},
		{
			field: "Note",
			headerName: "Note",
			minWidth: 170,
			filterable: false,
			renderCell: (params) => {
				return (
					<Box sx={{ display: "flex" }}>
						<Button
							onClick={() => {
								setPay(true);
								setDatas(params?.row?.invitedBy);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							$
						</Button>
						<Button
							onClick={() => {
								setOpenNote(true);
								setDatas(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<NoteAddIcon />
						</Button>
					</Box>
				);
			},
		},
		{
			field: "invitedRecrutedBy",
			headerName: "Inviter Recruted By",
			minWidth: 130,
		},
		{
			field: "RecruterPaidAmount",
			headerName: "Recruiter Paid Amount",
			minWidth: 150,
			valueGetter: (params) => params?.row?.recruterPaidAmount,
			renderCell: (params) => {
				return params?.row?.recruterPaidStatus !== "Paid" ? (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
						}}>
						<TextField
							size='small'
							sx={{ width: "70px" }}
							fullWidth
							label='$$$'
							type='number'
							defaultValue={params?.row?.recruterPaidAmount}
							onChange={(e) => setAmounts(e.target.value)}
						/>
						<Button
							className='border'
							onClick={() => {
								setSubmitting(true);
								axios
									.put(
										`${process.env.REACT_APP_API_PATH}/successfullSellsAmoutSetRecruter/${params?.row?._id}`,
										{ amount: amounts },
									)
									.then(function (response) {
										setSubmitting(false);
										Swal.fire({
											icon: "success",
											title: "Successfully Marked As Paid",
											showConfirmButton: true,
											timer: 2500,
										});
									})
									.catch(function (error) {
										console.log(error);
									});
							}}
							variant='contained'
							sx={{
								maxWidth: "10px !important",
								ml: 0.2,
							}}>
							<DoneIcon />
						</Button>
					</Box>
				) : (
					<Typography variant='p'>
						${params?.row?.recruterPaidAmount}
					</Typography>
				);
			},
		},
		{
			field: "RecruterPaidStatus",
			headerName: "Recruiter Paid Status",
			minWidth: 120,
			valueGetter: (params) => params?.row?.recruterPaidStatus,
			renderCell: (params) => {
				return params?.row?.recruterPaidStatus === "Not Paid Yet" ? (
					<Button
						onClick={() => {
							setSubmitting(true);
							Swal.fire({
								title: "Are you sure?",
								text: "You won't be able to revert this!",
								icon: "warning",
								showCancelButton: true,
								confirmButtonColor: "#3085d6",
								cancelButtonColor: "#d33",
								confirmButtonText: `Yes, Marked As Paid`,
							}).then((result) => {
								if (result.isConfirmed) {
									axios
										.put(
											`${process.env.REACT_APP_API_PATH}/successfullSellsRecruterPaidStatus/${params?.row?._id}`,
											{
												status: "Paid",
												time: new Date().toLocaleString("en-US", {
													timeZone: "America/Los_Angeles",
													year: "numeric",
													month: "2-digit",
													day: "2-digit",
													hour: "2-digit",
													minute: "2-digit",
													second: "2-digit",
													timeZoneName: "short",
												}),
											},
										)
										.then(function (response) {
											setSubmitting(false);
											Swal.fire({
												icon: "success",
												title: "Successfully Marked As Paid",
												showConfirmButton: true,
												timer: 2500,
											});
										})
										.catch(function (error) {
											console.log(error);
										});
								}
							});
						}}
						className='sendButton'
						type='submit'
						variant='contained'
						sx={{
							minWidth: "150px",
							borderRadius: "25px",
						}}>
						Mark as Paid
					</Button>
				) : (
					<Typography variant='p'>
						{params?.row?.recruterPaidStatus || "N/A"}
					</Typography>
				);
			},
		},
		{
			field: "recruterPaidOn",
			headerName: "Recruiter Paid On",
			minWidth: 220,
		},
		{
			field: "Action",
			headerName: "Recruiter Note",
			minWidth: 170,
			filterable: false,
			renderCell: (params) => {
				return (
					<Box sx={{ display: "flex" }}>
						<Button
							onClick={() => {
								setPay(true);
								setDatas(params?.row?.invitedRecrutedBy);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							$
						</Button>
						<Button
							onClick={() => {
								setOpenRecruterNote(true);
								setDatas(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<NoteAddIcon />
						</Button>
					</Box>
				);
			},
		},
	];

	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				All Funded Users
			</Typography>
			<Typography className='textColor'>
				Recruiter Commission Total :- ${inviterRecruterTotal}
				<br />
				Inviters Commission Total :- ${inviterTotal}
			</Typography>
			<Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
				<Button
					className={filtered === "" && "activeBtnPayment"}
					onClick={() => setFiltered("")}
					variant='contained'
					sx={{
						px: 3,
						mx: 1,
						fontWeight: "bold",
						borderRadius: "25px",
						border: "1px solid",
					}}>
					All Users
				</Button>
				<Button
					className={filtered === "unpaid" && "activeBtnPayment"}
					onClick={() => setFiltered("unpaid")}
					variant='contained'
					sx={{
						px: 3,
						mx: 1,
						fontWeight: "bold",
						borderRadius: "25px",
						border: "1px solid",
					}}>
					All UnPaid
				</Button>
				<Button
					className={filtered === "paid" && "activeBtnPayment"}
					onClick={() => setFiltered("paid")}
					variant='contained'
					sx={{
						px: 3,
						mx: 1,
						fontWeight: "bold",
						borderRadius: "25px",
						border: "1px solid",
					}}>
					All Paid
				</Button>
			</Box>
			{sells && (
				<Box sx={{ width: "100%", height: "65vh" }}>
					<DataGrid
						rows={sells}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: () => (
								<Search
									setSearchQuery={setSearchQuery}
									searchFields={[
										"assignedTo",
										"customer",
										"customerPhone",
										"invitedBy",
										"paidOn",
										"paidStatus",
										"recruterPaidOn",
										"recruterPaidStatus",
										"soldOn",
									]}
								/>
							),
							Footer: () => (
								<Pagination
									totalItems={totalItems}
									currentPage={currentPage}
									itemsPerPage={itemsPerPage}
									onPageChange={handlePageChange}
								/>
							),
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting || !databyEmail}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{openNote && (
				<Note openNote={openNote} setOpenNote={setOpenNote} datas={datas} />
			)}
			{openRecruterNote && (
				<NoteAboutRecruter
					openRecruterNote={openRecruterNote}
					setOpenRecruterNote={setOpenRecruterNote}
					datas={datas}
				/>
			)}
			{pay && <Payment datas={datas} pay={pay} setPay={setPay} />}
		</Box>
	);
};

export default AgentsPayments;
