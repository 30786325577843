import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import Calendar from "react-awesome-calendar";
import CalanderManage from "./CalanderManage";
import AllEvents from "./AllEvents";
import { useAPI } from "../../../../apiContext";

const Calander = () => {
	const { databyEmail } = useAPI();
	const [calanders, setCalanders] = useState();
	const [open, setOpen] = useState(false);
	const [opens, setOpens] = useState(false);
	const [state, setState] = useState(false);
	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/calander`)
			.then((res) => res.json())
			.then((data) => {
				setCalanders(data.reverse());
			});
	}, [state]);

	return (
		<Box sx={{ px: 2 }}>
			{databyEmail && calanders ? (
				<>
					{databyEmail?.role === "Admin" && (
						<Button sx={{ m: 2 }} onClick={() => setOpen(true)}>
							Add Events
						</Button>
					)}
					{databyEmail?.role === "Admin" && (
						<Button sx={{ m: 2 }} onClick={() => setOpens(true)}>
							All Events
						</Button>
					)}
					<Calendar events={calanders} />
					{open && (
						<CalanderManage
							setState={setState}
							open={open}
							setOpen={setOpen}
							data={databyEmail}
						/>
					)}
					{opens && (
						<AllEvents
							setState={setState}
							opens={opens}
							setOpens={setOpens}
							data={databyEmail}
						/>
					)}
				</>
			) : (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
		</Box>
	);
};

export default Calander;
