import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	IconButton,
	Rating,
	Backdrop,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styled from "@emotion/styled";
import StarIcon from "@mui/icons-material/Star";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: 24,
	p: 2,
	maxWidth: "95vw",
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	borderRadius: 3,
};

const AddReview = ({ open, setOpen, data }) => {
	const form = useRef();
	const [ratings, setRatings] = React.useState();
	/* const [hover, setHover] = React.useState(-1); */
	const [submitting, setSubmitting] = useState(false);
	const [imageLink2, setImageLink2] = useState(null);
	const { register, handleSubmit, reset } = useForm();
	const onSubmit = ({ userName, userReview }) => {
		const data = {
			reviewId: `review${Math.floor(100000 + Math.random() * 900000)}`,
			userName,
			userReview,
			userPhoto: imageLink2 || "/user.jpg",
			rating: ratings,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
		};
		setSubmitting(true);
		axios
			.put(`${process.env.REACT_APP_API_PATH}/review/${data?._id}`, data)
			.then(function (response) {
				setSubmitting(false);
				reset();
				setOpen(false);
				Swal.fire({
					icon: "success",
					title: "Your Review Successfully Added",
					showConfirmButton: true,
					timer: 950000,
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const [loading, setLoading] = useState(false);
	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append("file", files[0]);
		data.append("upload_preset", "Review");
		setLoading(true);
		const res = await fetch(
			"https://api.cloudinary.com/v1_1/america-solar/image/upload",
			{
				method: "POST",
				body: data,
			},
		);
		const file = await res.json();
		setImageLink2(file.secure_url);
		setLoading(false);
	};
	const Input = styled("input")({
		display: "none",
	});
	return (
		<div>
			{data?._id ? (
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open}>
						<Box sx={style} style={{ backgroundImage: `url(/bg-img.jpg)` }}>
							<CancelIcon
								onClick={() => setOpen(false)}
								className='textColor'
								sx={{
									position: "fixed",
									top: "5px",
									right: "5px",
									cursor: "pointer",
								}}
							/>
							<Typography
								className='textColor'
								sx={{
									fontWeight: 900,
									textAlign: "center",
								}}
								variant='h4'
								component='div'
								gutterBottom
								data-aos='fade-right'>
								Leave A Review
								<Typography
									variant='caption'
									display='block'
									sx={{ color: "red" }}>
									Your posts will appear publicly with your profile name and
									picture. <br /> Your posts will appear across the web.
								</Typography>
							</Typography>

							<Grid container spacing={2}>
								<Grid item md={7} xs={12} sx={{ mx: "auto" }}>
									<form
										ref={form}
										data-aos='fade-left'
										onSubmit={handleSubmit(onSubmit)}>
										<Box
											display='flex'
											flexDirection='column'
											alignItems='center'
											sx={{ mt: 3, mb: 1, mx: "auto" }}>
											<label
												className='bgColor'
												htmlFor='icon-button-file'
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													margin: "0 9px",
													borderRadius: 5,
													cursor: "pointer",
													minWidth: "280px",
												}}>
												<Input
													accept='image/*'
													id='icon-button-file'
													type='file'
													onChange={uploadImage}
												/>
												<Typography
													sx={{ my: 2, ml: 2, color: "white" }}
													variant='button'
													component='div'
													gutterBottom>
													Your Photo (Optional)
												</Typography>
												<IconButton
													sx={{ mx: 2 }}
													color='primary'
													aria-label='upload picture'
													component='span'>
													<AccountCircleIcon
														fontSize='large'
														sx={{ fontWeight: "bold" }}
													/>
												</IconButton>
											</label>

											{loading ? (
												<Box sx={{ my: 2 }}>
													<CircularProgress className='textColor' />
												</Box>
											) : (
												<img
													src={imageLink2}
													style={{ width: "200px" }}
													alt=''
												/>
											)}
										</Box>
										<Box
											sx={{
												mt: 3,
												display: "flex",
												justifyContent: "center",
											}}>
											<Rating
												className='color-theme'
												sx={{
													float: "left",
													mb: 2,
													fontSize: 60,
												}}
												name='hover-feedback'
												value={ratings}
												size='large'
												precision={0.5}
												onChange={(event, newValue) => {
													setRatings(newValue);
												}}
												onChangeActive={(event, newHover) => {
													/* setHover(newHover); */
												}}
												emptyIcon={
													<StarIcon
														className='color-text'
														style={{ opacity: 0.55 }}
														fontSize='inherit'
													/>
												}
											/>
											{/* <Typography
										className='color-theme'
										variant='h4'
										component='div'
										sx={{ ml: 2, mb: 1.7 }}>
										{hover !== -1 ? hover : value}
									</Typography> */}
										</Box>
										{ratings && (
											<>
												<input
													type='text'
													value={ratings}
													hidden
													{...register("ratings", { required: true })}
												/>
												<TextField
													required
													sx={{ width: "100%", mb: 2 }}
													id='"outlined-multiline-flexible'
													label='Name'
													{...register("userName", { required: true })}
												/>
												<TextField
													required
													sx={{ width: "100%", mb: 2 }}
													id='"outlined-multiline-flexible'
													label='Share Your Review'
													multiline
													rows={4}
													{...register("userReview", { required: true })}
												/>
												<Button
													type='submit'
													variant='contained'
													className='button border'
													sx={{
														width: "100%",
														mb: 2,
														px: 3,
														fontWeight: "bold",
														border: "2px solid",
														backgroundColor: "transparent",
														borderRadius: "25px",
													}}>
													POST REVIEW
												</Button>
											</>
										)}
									</form>
								</Grid>
							</Grid>
						</Box>
					</Fade>
				</Modal>
			) : (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	);
};

export default AddReview;
