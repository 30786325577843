import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Fade,
	Grid,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import axios from "axios";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import Note from "./Note";
import StatusChange from "./StatusChange";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { md: "40vw", sm: "60vw", xs: "95vw" },
	maxHeight: "90vh",
	overflowX: "hidden",
	overflowY: "scroll",
	bgcolor: "background.paper",
	border: "2px solid",
	boxShadow: 24,
	p: 4,
};

const RefferelsList = () => {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [openNote, setOpenNote] = useState(false);
	const [users, setUsers] = useState();
	const [user, setUser] = useState();
	const [submitting, setSubmitting] = useState(false);
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_PATH}/Refferels`)
			.then((res) => res.json())
			.then((data) => {
				setUsers(data?.reverse());
			});
	}, [submitting]);
	const [filtered, setFiltered] = useState("all");
	const [filteredData, setFilteredData] = useState(users);
	useEffect(() => {
		if (filtered === "all") {
			setFilteredData(users);
		}
		if (filtered === "Pending") {
			setFilteredData(users?.filter((user) => user?.status === "Pending"));
		}
		if (filtered === "Closed") {
			setFilteredData(users?.filter((user) => user?.status === "Closed"));
		}
	}, [filtered, users]);
	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			setSubmitting(true);
			if (result.isConfirmed) {
				axios
					.delete(`${process.env.REACT_APP_API_PATH}/Refferels/${id}`)
					.then(function (response) {
						Swal.fire("Deleted!", "That referrel has been deleted.", "success");
						setSubmitting(false);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		});
	};
	/* 
	let count = 1;
	const [search, setSearch] = React.useState("");
	const handleChange = (event) => {
		setSearch(event.target.value);
	};
	const [newList, setNewList] = React.useState([]);
	useEffect(() => {
		const searchs = filteredData?.filter(
			(user) =>
				user?.name?.toLowerCase().includes(search.toLowerCase()) ||
				user?.email?.toLowerCase().includes(search.toLowerCase()) ||
				user?.phone?.toLowerCase().includes(search.toLowerCase()) ||
				user?.userName?.toLowerCase().includes(search.toLowerCase()) ||
				user?.phone?.toLowerCase().includes(search.toLowerCase()) ||
				user?.id?.toLowerCase().includes(search.toLowerCase()),
		);
		setNewList(searchs);
	}, [filteredData, search]);

	const [perpage, setPerpage] = React.useState(5);
	const [newArray, setNewArray] = React.useState();
	const [page, setPage] = React.useState(1);
	const handleChanges = (event, value) => {
		setPage(value);
	};
	useEffect(() => {
		setNewArray(newList?.slice((page - 1) * perpage, page * perpage));
	}, [newList, page, perpage]); */

	const columns = [
		{
			field: "id",
			headerName: "ID",
			minWidth: 150,
		},
		{
			field: "submitTime",
			headerName: "Submitted",
			minWidth: 220,
		},
		{
			field: "referralsName",
			headerName: "Referrals Name",
			minWidth: 150,
		},
		{
			field: "referralsNumber",
			headerName: "Referrals Number",
			minWidth: 150,
			valueGetter: (params) => params?.row?.referralsNumber,
			renderCell: (params) => {
				return (
					<a
						href={`tel:${params?.row?.referralsNumber}`}
						className='textColor'
						style={{ textDecoration: "none" }}>
						{params?.row?.referralsNumber}
					</a>
				);
			},
		},
		{
			field: "referrerName",
			headerName: "Referrer Name",
			minWidth: 150,
		},
		{
			field: "status",
			headerName: "Status",
			minWidth: 140,
			valueGetter: (params) => params?.row?.status,
			renderCell: (params) => {
				return params?.row?.status !== "Closed" ? (
					<StatusChange user={params?.row} setSubmitting={setSubmitting} />
				) : (
					<span>{params?.row?.status}</span>
				);
			},
		},
		{
			field: "Action",
			headerName: "Action",
			minWidth: 250,
			filterable: false,
			renderCell: (params) => {
				return (
					<Box sx={{ display: "flex" }}>
						<Button
							onClick={() => {
								handleOpen();
								setUser(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<RemoveRedEyeIcon />
						</Button>
						<Button
							onClick={() => {
								setOpenNote(true);
								setUser(params?.row);
							}}
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<NoteAddIcon />
						</Button>
						<Button
							className='button border'
							onClick={() => handleDelete(params?.row?._id)}
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							variant='contained'>
							<DeleteIcon />
						</Button>
					</Box>
				);
			},
		},
	];

	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
			}}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'>
				All Referrels
			</Typography>{" "}
			<Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
				<Button
					className={filtered === "all" && "activeBtnPayment"}
					onClick={() => setFiltered("all")}
					variant='contained'
					sx={{
						px: 3,
						mx: 1,
						fontWeight: "bold",
						borderRadius: "25px",
						border: "1px solid",
					}}>
					All
				</Button>
				<Button
					className={filtered === "Pending" && "activeBtnPayment"}
					onClick={() => setFiltered("Pending")}
					variant='contained'
					sx={{
						px: 3,
						mx: 1,
						fontWeight: "bold",
						borderRadius: "25px",
						border: "1px solid",
					}}>
					Pending
				</Button>
				<Button
					className={filtered === "Closed" && "activeBtnPayment"}
					onClick={() => setFiltered("Closed")}
					variant='contained'
					sx={{
						px: 3,
						mx: 1,
						fontWeight: "bold",
						borderRadius: "25px",
						border: "1px solid",
					}}>
					Closed
				</Button>
			</Box>
			{filteredData && (
				<Box sx={{ width: "100%", height: "70vh" }}>
					<DataGrid
						rows={filteredData}
						getRowId={(row) => row?._id}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableSelectionOnClick
					/>
				</Box>
			)}
			{/* 	<Box sx={{ height: "115px", mx: 2 }}>
				<Typography className='textColor'>
					Updated in {users?.[0]?.submitTime || "N/A"}
				</Typography>
				<TextField
					size='small'
					sx={{ width: "100%", maxWidth: "550px" }}
					id='outlined-name'
					placeholder='Search by ID / Name / Email / Phone / Meeting Code'
					value={search}
					onChange={handleChange}
				/>
			</Box> */}
			{/* <Paper
				className='container'
				sx={{
					overflow: "auto",
					maxHeight: "calc(90vh - 115px)",
					maxWidth: "90vw",
					mx: 2,
				}}>
				<Box
					className='pagination'
					sx={{ display: "flex", justifyContent: "center" }}>
					<FormControl>
						<NativeSelect
							defaultValue={perpage}
							onChange={(e) => setPerpage(e.target.value)}>
							<option value={5}>5</option>
							<option value={10}>10</option>
							<option value={15}>15</option>
							<option value={20}>20</option>
							<option value={40}>40</option>
							<option value={50}>50</option>
						</NativeSelect>
					</FormControl>
					<Pagination
						sx={{
							display: "flex",
							justifyContent: "center !important",
						}}
						count={Math.ceil(newList?.length / perpage)}
						page={page}
						onChange={handleChanges}
						variant='outlined'
						shape='rounded'
					/>
				</Box>
				<Table size='small' stickyHeader aria-label='sticky table'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='left'>No</TableCell>
							<TableCell align='left'>ID</TableCell>
							<TableCell align='left'>Submitted</TableCell>
							<TableCell align='left'>Referrals Name</TableCell>
							<TableCell align='left'>Referrals Number</TableCell>
							<TableCell align='left'>Referrer Name</TableCell>
							<TableCell align='left'>Status</TableCell>
							<TableCell align='center'>Action</TableCell>
						</TableRow>
					</TableHead>
					{users?.length > 0 ? (
						<TableBody sx={{ td: { py: 1 } }}>
							{newArray?.map((user, key) => (
								<TableRow
									key={key}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
									}}>
									<TableCell align='left'>{count++}</TableCell>
									<TableCell align='left'>{user?.id}</TableCell>
									<TableCell align='left'>{user?.submitTime}</TableCell>
									<TableCell align='left'>{user?.referralsName}</TableCell>
									<TableCell align='left'>
										<a
											href={`tel:${user?.referralsNumber}`}
											className='textColor'
											style={{ textDecoration: "none" }}>
											{user?.referralsNumber}
										</a>
									</TableCell>
									<TableCell align='left'>{user?.referrerName}</TableCell>
									<TableCell align='left'>
										{user?.status !== "Closed" ? (
											<StatusChange user={user} setSubmitting={setSubmitting} />
										) : (
											<span>{user?.status}</span>
										)}
									</TableCell>
									<TableCell align='center'>
										<Box sx={{ display: "flex" }}>
											<Button
												onClick={() => {
													handleOpen();
													setUser(user);
												}}
												className='button border'
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<RemoveRedEyeIcon />
											</Button>
											<Button
												onClick={() => {
													setOpenNote(true);
													setUser(user);
												}}
												className='button border'
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<NoteAddIcon />
											</Button>
											<Button
												className='button border'
												onClick={() => handleDelete(user?._id)}
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
												}}
												variant='contained'>
												<DeleteIcon />
											</Button>
										</Box>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : (
						<TableHead sx={{ th: { fontWeight: "bold" } }}>
							<TableRow>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
								<TableCell align='left'>N/A</TableCell>
							</TableRow>
						</TableHead>
					)}
				</Table>
			</Paper> */}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!users}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{openNote && (
				<Note openNote={openNote} setOpenNote={setOpenNote} user={user} />
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={open}>
					<Box sx={style}>
						<Grid container spacing={2}>
							<Grid item md={6} xs={12}>
								<TextField
									size='small'
									sx={{ width: "100%", my: 0.7 }}
									id='outlined-basic'
									name='referralsName'
									label='Referrals Name'
									value={user?.referralsName}
								/>
								<TextField
									size='small'
									sx={{ width: "100%", my: 0.7 }}
									id='outlined-basic'
									name='referralsEmail'
									type='email'
									label='Referrals Email'
									value={user?.referralsEmail}
								/>
								<TextField
									size='small'
									sx={{ width: "100%", mt: 0.7 }}
									id='outlined-basic'
									name='referralsNumber'
									type='tel'
									label='Referrals Number'
									value={user?.referralsNumber}
								/>
							</Grid>

							<Grid item md={6} xs={12}>
								<TextField
									size='small'
									sx={{ width: "100%", my: 0.7 }}
									id='outlined-basic'
									name='referrerName'
									label='Referrer Name'
									value={user?.referrerName}
								/>
								<TextField
									size='small'
									sx={{ width: "100%", my: 0.7 }}
									id='outlined-basic'
									name='referrerEmail'
									type='email'
									label='Referrer Email'
									value={user?.referrerEmail}
								/>
								<TextField
									size='small'
									sx={{ width: "100%", mt: 0.7 }}
									id='outlined-basic'
									name='referrerNumber'
									type='tel'
									label='Referrer Number'
									value={user?.referrerNumber}
								/>
							</Grid>
							<Grid item md={12} xs={12}>
								<TextField
									size='small'
									multiline
									rows={2}
									sx={{ width: "100%" }}
									id='outlined-basic'
									name='note'
									label='Note'
									value={user?.note}
								/>
							</Grid>
						</Grid>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
};

export default RefferelsList;
